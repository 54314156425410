










import { wasPatientAutoCreateRestricted } from '@/+state/patient-details/projections';
import { select } from '@conversa/reflex';
import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  setup() {
    return {
      message: computed(() => {
        if (select(wasPatientAutoCreateRestricted).value) {
          return 'Patient record not found';
        }

        return 'No care programs enrolled for this patient';
      }),
    };
  },
});
