import { handle } from '@/+state';
import { BannerDataUpdated } from '@/+state/banner/events';
import { EnrollmentUpdateStatusClicked } from '@/+state/enrollments/events';
import { ContextInitialized, MiscLoading } from '@/+state/misc/events';
import EnrollmentProgramListVue from '@/dialogs/enrollments/EnrollmentProgramList.vue';
import { handleApiError } from '@/shared/api/handle-api-error';
import { EnrollmentStatusEnum } from '@/shared/models';
import { Program } from '@/shared/models/survey.interface';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';
import { emit } from '@conversa/reflex';
import sanitizeHtml from 'sanitize-html';
import * as events from './events';
import { patientDetailSummaryEnrollmentsInitialState } from './store';

export function transformProgram(program: Program): Program {
  if (!program || !program.description) {
    return program;
  }

  program.description = sanitizeHtml(program.description ?? '', {
    allowedTags: ['br', 'hr', 'em', 'strong'],
  });
  return program;
}

export const getEnrollmentsHandler = handle(
  [ContextInitialized],
  async ({ core, plugins }) => {
    const store = core.stores.patientDetailSummaryEnrollmentsStore;

    try {
      store.loading = true;
      store.error = null;

      const res = await plugins.api.getEnrollments();
      store.enrollments = res.data;

      if (!res.data.length) {
        DialogInstance.open(EnrollmentProgramListVue, {
          closable: false,
          fullscreen: true,
        });
      }
    } catch (error) {
      handleApiError(store, error);
    } finally {
      store.loading = false;
      emit(MiscLoading({ isLoading: false }));
    }
  },
);

export const getProgramHandler = handle(
  [events.EnrollmentEditClicked],
  async ({ core, plugins }) => {
    const store = core.stores.patientDetailSummaryEnrollmentsStore;
    const localProgram =
      store.programs.find(
        program => program.authoringId === core.event.payload.id,
      ) || null;
    const programId = core.event.payload.id;

    try {
      store.loading = true;
      store.error = null;

      if (!localProgram) {
        const res = await plugins.api.getProgram(programId);
        store.selectedProgram = transformProgram(res.data);
      } else {
        store.selectedProgram = localProgram;
      }

      DialogInstance.open(core.event.payload.component, { fullscreen: true });
    } catch (error) {
      handleApiError(store, error);
    } finally {
      store.loading = false;
    }
  },
);

export const getProgramsHandler = handle(
  [ContextInitialized, events.PatientEnrollmentSuccessful],
  async ({ core, plugins }) => {
    const store = core.stores.patientDetailSummaryEnrollmentsStore;

    try {
      store.loading = true;
      store.error = null;

      const res = await plugins.api.getPrograms();

      store.programs = res.data?.map(program => transformProgram(program));
    } catch (error) {
      handleApiError(store, error);
    } finally {
      store.loading = false;
    }
  },
);

export const surveySelectedHandler = handle(
  [
    events.SurveySelected,
    events.EnrollmentEditClicked,
    events.EnrollmentChangeStatusClicked,
  ],
  ({ core }) => {
    const store = core.stores.patientDetailSummaryEnrollmentsStore;
    store.selectedProgram =
      store.programs.find(
        program => program.authoringId === core.event.payload.id,
      ) || null;
  },
);

export const enrollmentSelectedHandler = handle(
  [events.EnrollmentEditClicked, events.EnrollmentChangeStatusClicked],
  ({ core }) => {
    const store = core.stores.patientDetailSummaryEnrollmentsStore;
    store.selectedEnrollment =
      store.enrollments.find(
        enrollment => enrollment.surveyAuthoringId === core.event.payload.id,
      ) || null;
  },
);

export const postEnrollmentsHandler = handle(
  [events.EnrollmentSubmitted],
  async ({ core, plugins }) => {
    const store = core.stores.patientDetailSummaryEnrollmentsStore;
    const payload = {
      enrollmentQuestionAnswers: core.event.payload.enrollmentQuestionAnswers,
      programId: core.event.payload.programId,
      active: true,
      reEnroll: false,
    };

    try {
      store.loading = true;
      store.error = null;

      const res = await plugins.api.postEnrollments(payload);

      store.enrollments.push(res.data);
      store.selectedEnrollment = res.data;
      emit(events.PatientEnrollmentSuccessful());
      DialogInstance.close();
    } catch (error) {
      handleApiError(store, error);
    } finally {
      store.loading = false;
    }
  },
);

export const patchEnrollmentsHandler = handle(
  [events.EnrollmentUpdateSubmitted],
  async ({ core, plugins }) => {
    const store = core.stores.patientDetailSummaryEnrollmentsStore;
    const payload = {
      active: true,
      enrollmentQuestionAnswers: core.event.payload.enrollmentQuestionAnswers,
    };

    try {
      store.loading = true;
      store.error = null;

      const res = await plugins.api.patchEnrollments(
        store.selectedEnrollment.id,
        payload,
      );

      const enrollmentInd = store.enrollments.findIndex(
        e => e.id === res.data.id,
      );

      store.enrollments.splice(enrollmentInd, 1, res.data);
      DialogInstance.close();
    } catch (error) {
      handleApiError(store, error);
    } finally {
      store.loading = false;
    }
  },
);

export const enrollmentChangeStatusHandler = handle(
  [EnrollmentUpdateStatusClicked],
  async ({ core, plugins }) => {
    const store = core.stores.patientDetailSummaryEnrollmentsStore;

    try {
      store.loading = true;
      store.error = null;

      const res = await plugins.api.updateEnrollmentStatus(
        core.event.payload.authoringId,
        core.event.payload.newApprovalStatus,
        core.event.payload.enrollmentAnswers,
      );

      const currentEnrollmentIndex = store.enrollments.findIndex(
        enrollment => enrollment.id === res.data.id,
      );
      store.enrollments.splice(currentEnrollmentIndex, 1, res.data);

      emit(events.EnrollmentChangeStatusSucceeded());
      DialogInstance.close();
    } catch (error) {
      handleApiError(store, error);
    } finally {
      store.loading = false;
    }
  },
);

export const enrollmentSuccessfulBannerHandler = handle(
  [events.PatientEnrollmentSuccessful],
  ({ core }) => {
    const store = core.stores.patientDetailSummaryEnrollmentsStore;
    const patientInfo = core.stores.patientsDetailStore.data;
    const enrollmentStatus =
      {
        [EnrollmentStatusEnum.Approved]: 'enrolled in',
      }[store.selectedEnrollment.approvalStatus] ?? null;

    if (!patientInfo || !patientInfo.firstName || !enrollmentStatus) {
      emit(events.EnrollmentClearSelected());
      return;
    }

    emit(
      BannerDataUpdated({
        message: `${patientInfo.firstName} ${patientInfo.lastName} successfully ${enrollmentStatus} ${store.selectedEnrollment.surveyName}`,
        icon: 'check_circle_outline',
        iconColor: 'green',
      }),
    );
  },
);

export const clearSelectedEnrollment = handle(
  [events.EnrollmentClearSelected, BannerDataUpdated],
  ({ core }) => {
    core.stores.patientDetailSummaryEnrollmentsStore.selectedEnrollment = null;
  },
);

export const clearSelectedSurvey = handle(
  [events.EnrollmentDetailsUnmounted],
  ({ core }) => {
    core.stores.patientDetailSummaryEnrollmentsStore.selectedProgram =
      patientDetailSummaryEnrollmentsInitialState.selectedProgram;
  },
);

export const enrollmentsHandlers = [
  clearSelectedSurvey,
  enrollmentChangeStatusHandler,
  enrollmentSelectedHandler,
  enrollmentSuccessfulBannerHandler,
  getEnrollmentsHandler,
  getProgramHandler,
  getProgramsHandler,
  patchEnrollmentsHandler,
  postEnrollmentsHandler,
  surveySelectedHandler,
];
