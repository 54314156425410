import { handle } from '@/+state';
import { handleApiError } from '@/shared/api/handle-api-error';
import { FetchCheckupResponsesRequested } from './events';

export const fetchCheckupResponsesRequested = handle(
  [FetchCheckupResponsesRequested],
  async ({ core, plugins }) => {
    const checkupId = core.event.payload.checkupId;
    const store = core.stores.checkupResponsesStore;
    let loadingTimeout;

    store.loadingCheckupId = {
      ...store.loadingCheckupId,
      [checkupId]: false,
    };
    store.renderComponent = {
      ...store.renderComponent,
      [checkupId]: false,
    };
    store.error = null;

    try {
      loadingTimeout = setTimeout(() => {
        store.loadingCheckupId = {
          ...store.loadingCheckupId,
          [checkupId]: true,
        };
        store.renderComponent = {
          ...store.renderComponent,
          [checkupId]: true,
        };
      }, 1000);

      const res = await plugins.api.getCheckupResponses(checkupId);

      store.data = {
        ...store.data,
        [checkupId]: res.data,
      };
    } catch (error) {
      handleApiError(store, error);
    } finally {
      clearTimeout(loadingTimeout);
      store.loadingCheckupId = {
        ...store.loadingCheckupId,
        [checkupId]: false,
      };
      store.renderComponent = {
        ...store.renderComponent,
        [checkupId]: true,
      };
    }
  },
);

export const checkupResponsesHandlers = [fetchCheckupResponsesRequested];
