import PatientDetails from '@/components/patient-details/Shell.vue';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    redirect: '/summary',
  },
  {
    path: '/summary',
    name: 'patientDetails',
    // Lazy loading was causing the access_token to not be stripped on redirect to this route
    components: {
      default: PatientDetails,
    },
  },
  {
    path: '/checkups',
    component: () => import('@/components/checkups/Shell.vue'),
  },
  {
    path: '/schedule',
    component: () => import('@/components/survey-sessions/Shell.vue'),
  },
  {
    path: '/activity',
    component: () => import('@/components/activity/Shell.vue'),
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

export default router;
