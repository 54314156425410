




































import { computed, defineComponent, ref } from '@vue/composition-api';
import moment from 'moment';
import { dateValidRule } from '@/shared/date-format-rule';
import { ymdLeadZerosWDash } from '@/constants/dateTime';

export default defineComponent({
  props: ['inputValue', 'required', 'disabled', 'rules', 'dateFormat'],
  setup(props, ctx) {
    const dateOpen = ref(false);

    return {
      dateOpen,
      date: computed(() => {
        // Value from date-picker must return YYYY-MM-DD
        if (!props?.inputValue || isNaN(Date.parse(props.inputValue)))
          return '';
        if (/^([0-9]{4}-[0-9]{2}-[0-9]{2})?$/.test(props.inputValue))
          return props.inputValue;
        if (dateValidRule(props.inputValue, props.dateFormat) === true)
          return moment(props.inputValue, props.dateFormat)
            .format(ymdLeadZerosWDash)
            .toString();

        return '';
      }),
      dateInput: computed(() => {
        // Manually entered dates are validated by EnrollmentDetailsDialog
        if (!props?.inputValue) return '';
        if (/^([0-9]{4}-[0-9]{2}-[0-9]{2})?$/.test(props.inputValue))
          return moment(props.inputValue)
            .format(props.dateFormat)
            .toString();
        return props.inputValue;
      }),
      onChange: event => {
        ctx.emit('change', event);
        dateOpen.value = false;
      },
      cancel: event => {
        ctx.emit('clear', event);
        dateOpen.value = false;
      },
    };
  },
});
