import { handle } from '@/+state';
import { ContextInitialized } from '@/+state/misc/events';
import { handleApiError } from '@/shared/api/handle-api-error';

export const loadProviders = handle(
  [ContextInitialized],
  async ({ core, plugins }) => {
    const store = core.stores.providersStore;
    store.loadingProviders = true;
    store.error = null;

    try {
      const res = await plugins.api.getProviders();
      store.providers = res.data;
    } catch (error) {
      handleApiError(store, error);
    } finally {
      store.loadingProviders = false;
    }
  },
);

export const providersHandlers = [loadProviders];
