import { project } from '@/+state';

export const loadingProviderFeatures = project<boolean>(
  ({ stores }) => stores.providerFeaturesStore.loading,
);

export const providerFeaturesAlertingEnabled = project<boolean>(
  ({ stores }) => stores.providerFeaturesStore.features.alertingEnabled,
);

export const providerFeaturesRestrictEnrollment = project<boolean>(
  ({ stores }) => stores.providerFeaturesStore.features.restrictEnrollment,
);

export const providerFeaturesSmsEnabled = project<boolean>(
  ({ stores }) => stores.providerFeaturesStore.features.smsEnabled,
);

export const providerFeaturesProjections = [
  loadingProviderFeatures,
  providerFeaturesAlertingEnabled,
  providerFeaturesRestrictEnrollment,
  providerFeaturesSmsEnabled,
];
