import { ComputedRef, inject } from '@vue/composition-api';
import { createProjection } from './projector';

/**
 * Allows a user to select a projection
 * This is useable inside of:
 *   - Vue component setup method
 *   - Handlers
 *   - Projections
 *
 * The reason it works in Handlers and Projections
 * is because their execution context is performed
 * inside of a Vue component setup method
 *
 * @export
 * @template T
 * @param {T} projection
 * @returns {ComputedRef<ReturnType<T['fn']>>}
 */
export function select<T extends ReturnType<typeof createProjection>>(
  projection: T,
): ComputedRef<ReturnType<T['fn']>> {
  return inject<ComputedRef<ReturnType<T['fn']>>>(projection.key);
}
