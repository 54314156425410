import { handle } from '@/+state';
import { ContextInitialized } from '@/+state/misc/events';
import { handleApiError } from '@/shared/api/handle-api-error';
import { emit } from '@conversa/reflex';
import {
  EnrollmentChangeStatusSucceeded,
  PatientEnrollmentSuccessful,
} from '../enrollments/events';
import {
  SurveySessionPageUpdated,
  SurveySessionsMounted,
  SurveySessionsUpdated,
  SurveySessionsUpdateScope,
} from './events';

export const getSurveySessionsHandler = handle(
  [
    ContextInitialized,
    SurveySessionsMounted,
    PatientEnrollmentSuccessful,
    EnrollmentChangeStatusSucceeded,
    SurveySessionsUpdated,
  ],
  async ({ core, plugins }) => {
    const store = core.stores.surveySessionsStore;
    let loadingTimeout;
    try {
      store.loading = false;
      loadingTimeout = setTimeout(() => (store.loading = true), 1000);
      store.error = null;

      const res = await plugins.api.getSessions(store.scope);

      store[store.scope] = res.data;
    } catch (error) {
      handleApiError(store, error);
    } finally {
      clearTimeout(loadingTimeout);
      store.loading = false;
    }
  },
);

export const surveySessionsUpdateScopeHandler = handle(
  [SurveySessionsUpdateScope],
  ({ core }) => {
    core.stores.surveySessionsStore.scope = core.event.payload.scope;
    // must reset page otherwise we get blank results if not greater than 10
    core.stores.surveySessionsStore.page = 1;
    emit(SurveySessionsUpdated());
  },
);

export const surveySessionPageUpdatedHandler = handle(
  [SurveySessionPageUpdated],
  ({ core }) => {
    core.stores.surveySessionsStore.page = core.event.payload.page;
  },
);

export const surveySessionsHandlers = [
  getSurveySessionsHandler,
  surveySessionsUpdateScopeHandler,
  surveySessionPageUpdatedHandler,
];
