





































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: ['title', 'header', 'description', 'link', 'to', 'dark'],
});
