









import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: ['content', 'focus'],
  setup() {
    //
  },
});
