import { project } from '@/+state';
import { HeaderData } from '@/models/patient-details/header-data.interface';
import { noDataIndicator } from '@/shared/content';
import { PatientDetailErrors } from '@/shared/models/patient-detail-errors.type';
import { formatPatientName } from '@/shared/utils';
import { dateFormatter } from '../../../../../shared/date-formatter';

export const wasPatientAutoCreateRestricted = project<boolean>(
  ({ stores }) => stores.patientsDetailStore.data?.autoCreatePatientRestricted,
);

export const patientsDetailStoreErrorMessage = project<string>(
  ({ stores }) => stores.patientsDetailStore.error?.message,
);

export const patientDetailStoreErrors = project<PatientDetailErrors>(
  ({ stores }) => stores.patientsDetailStore.error?.details?.errors,
);

export const profileLoading = project<boolean>(
  ({ stores }) => stores.patientsDetailStore.profileLoading,
);

export const statusLoading = project<boolean>(
  ({ stores }) => stores.patientsDetailStore.statusLoading,
);

export const resendChatLoading = project<boolean>(
  ({ stores }) => stores.patientsDetailStore.resendChatLoading,
);

export const hasPatientConsent = project<boolean>(
  ({ stores }) =>
    !!stores.patientsDetailStore.data?.preferredCommunicationChannel?.length,
);

export const patientDetailHeader = project<HeaderData>(({ stores }) => {
  return {
    patientName: formatPatientName(
      stores.patientsDetailStore.data?.firstName,
      stores.patientsDetailStore.data?.lastName,
    ),
    createdAt: stores.patientsDetailStore.data?.createdAt
      ? dateFormatter(
          stores.patientsDetailStore.data?.createdAt,
          stores.capabilitiesStore.capabilities.international.datetimeFormat,
          'date',
        )
      : noDataIndicator,
    patientId:
      stores.patientsDetailStore.data?.clinicPatientCode || noDataIndicator,
  };
});

export const patientDetailStatus = project<boolean>(({ stores }) => {
  return stores.patientsDetailStore.data?.optedOut;
});

export const patientDetailsProjections = [
  patientsDetailStoreErrorMessage,
  patientDetailStoreErrors,
  profileLoading,
  statusLoading,
  resendChatLoading,
  hasPatientConsent,
  patientDetailHeader,
  patientDetailStatus,
  wasPatientAutoCreateRestricted,
];
