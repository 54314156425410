import { onMounted, onUnmounted } from '@vue/composition-api';
import { Subscription } from 'rxjs';
import { emit, events$, ReflexInitialized } from './events';
import { createHandler } from './handler';
import { createProjection } from './projector';
import { store } from './store';

/**
 * Future state: no reason that this needs to be Class based anymore
 * given the changes with moving select and emit out as standalone functions
 * This will run fine with just functions and we could bring
 * in the `init` function directly into Vue components
 *
 * @export
 * @class Reflex
 * @template Stores
 * @template Plugins
 */
export class Reflex<
  Stores extends { [K: string]: ReturnType<typeof store> },
  Plugins extends {
    [K: string]: (stores: Record<string, any>) => any;
  } = any
> {
  private injectedStores: Record<string, any> = {};
  private plugins: { [k: string]: any };
  private reflex$: Subscription;

  constructor(
    private options: {
      handlers: ReturnType<typeof createHandler>[];
      logging?: boolean;
      plugins: Plugins;
      projections: ReturnType<typeof createProjection>[];
      stores: Stores;
    },
  ) {}

  init() {
    this.reflex$ = events$.subscribe(
      e => this.options.logging && console.log(e),
    );
    this.registerStores();
    this.registerProjections();
    this.registerHandlers();
    onMounted(() => emit(ReflexInitialized()));
    onUnmounted(() => this.reflex$.unsubscribe());
  }

  private getPlugins() {
    if (!this.plugins) {
      this.plugins = Object.keys(this.options.plugins).reduce((acc, curr) => {
        acc[curr] = this.options.plugins[curr](this.injectedStores);
        return acc;
      }, {});
    }

    return this.plugins;
  }

  private registerStores() {
    for (const key in this.options.stores) {
      this.options.stores[key].register();
    }

    this.injectedStores = Object.keys(this.options.stores).reduce(
      (acc, curr) => {
        acc[curr] = this.options.stores[curr].inject();
        return acc;
      },
      {},
    );
  }

  private registerProjections() {
    this.options.projections.forEach(projection => {
      projection.register(this.injectedStores);
    });
  }

  private registerHandlers() {
    this.options.handlers.forEach(handler => {
      this.reflex$.add(
        handler.register(events$, this.getPlugins(), this.injectedStores),
      );
    });
  }
}
