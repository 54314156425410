import { handle } from '@/+state';
import { UserAccessTokenInvalid } from '@/+state/user/events';
import AppErrorDialog from '@/dialogs/AppErrorDialog.vue';
import { handleApiError } from '@/shared/api/handle-api-error';
import { defaultAlertMessage } from '@/shared/content';
import { ErrorState } from '@/shared/models';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';
import { emit } from '@conversa/reflex';
import { activityInitialState } from '../activity/store';
import { capabilitiesInitialState } from '../capabilities/store';
import { checkupResponsesInitialState } from '../checkup-responses/store';
import { checkupsInitialState } from '../checkups/store';
import { patientDetailSummaryEnrollmentsInitialState } from '../enrollments/store';
import { patientsDetailInitialState } from '../patient-details/store';
import { providersInitialState } from '../providers/store';
import { surveySessionsInitialState } from '../survey-sessions/store';
import { UserAccessTokenSet } from '../user/events';
import { userInitialState } from '../user/store';
import { ContextInitialized, DialogUnmounted, MiscLoading } from './events';
import { miscInitialState } from './store';

export const miscLoadingHandler = handle([MiscLoading], ({ core }) => {
  if (core.event.payload.isLoading) {
    core.stores.miscStore.loading.push(0);
  } else {
    core.stores.miscStore.loading.pop();
  }
});

function handleContextErrors(error?: ErrorState) {
  if (!error) {
    return;
  }

  let errorMessage = defaultAlertMessage;
  if (error.message) {
    if (error.message.match(/Unable to resolve practitioner/i)) {
      errorMessage =
        'We were unable to load your user details. Please refresh the page to try again. If this persists, please contact support.';
    }
    if (error.message.match(/Unable to resolve patient/i)) {
      errorMessage =
        'We were unable to load the patient details. Please refresh the page to try again. If this persists, please contact support.';
    }
  }

  DialogInstance.open(AppErrorDialog, {
    message: errorMessage,
  });
}

export const initialContextHandler = handle(
  [UserAccessTokenSet],
  async ({ core, plugins }) => {
    core.stores.miscStore.error = null;

    try {
      const context = await plugins.api.getContext();

      if (context?.patient) {
        if (!context.patient.providerCode) {
          context.patient.providerCode = context.user.providerCode;
        }

        core.stores.patientsDetailStore.data = context.patient;
        core.stores.capabilitiesStore.capabilities = context.capabilities;

        emit(ContextInitialized());
      }
    } catch (error) {
      handleApiError(core.stores.miscStore, error);
      handleContextErrors(core.stores.miscStore.error);
    }
  },
);

export const resetAllStores = handle([UserAccessTokenInvalid], ({ core }) => {
  Object.assign(core.stores.activityStore, activityInitialState);
  Object.assign(core.stores.capabilitiesStore, capabilitiesInitialState);
  Object.assign(
    core.stores.checkupResponsesStore,
    checkupResponsesInitialState,
  );
  Object.assign(core.stores.checkupsStore, checkupsInitialState);
  Object.assign(
    core.stores.patientDetailSummaryEnrollmentsStore,
    patientDetailSummaryEnrollmentsInitialState,
  );
  Object.assign(core.stores.miscStore, miscInitialState);
  Object.assign(core.stores.patientsDetailStore, patientsDetailInitialState);
  Object.assign(core.stores.providersStore, providersInitialState);
  Object.assign(core.stores.surveySessionsStore, surveySessionsInitialState);
  Object.assign(core.stores.userStore, userInitialState);
});

/**
 * At the time of writing this handler
 * not all stores had an error property
 */
export const clearErrorStateHandler = handle([DialogUnmounted], ({ core }) => {
  core.stores.activityStore.error = null;
  core.stores.checkupResponsesStore.error = null;
  core.stores.checkupsStore.error = null;
  core.stores.patientDetailSummaryEnrollmentsStore.error = null;
  core.stores.patientsDetailStore.error = null;
  core.stores.surveySessionsStore.error = null;
});

export const miscHandlers = [
  miscLoadingHandler,
  resetAllStores,
  clearErrorStateHandler,
  initialContextHandler,
];
