










import {
  EnrollmentEditClicked,
  EnrollmentUpdateStatusClicked,
} from '@/+state/enrollments/events';
import {
  enrollmentsError,
  enrollmentsLoading,
  selectedEnrollment,
} from '@/+state/enrollments/projections';
import { EnrollmentStatusEnum } from '@/shared/models';
import { emit, select } from '@conversa/reflex';
import { defineComponent } from '@vue/composition-api';
import EnrollmentReenrollConceptsDialogVue from './EnrollmentReenrollConceptsDialog.vue';
import EnrollmentStatusDialog from './EnrollmentStatusDialog.vue';

export default defineComponent({
  components: {
    EnrollmentStatusDialog,
  },
  setup(_, ctx) {
    const enrollment = select(selectedEnrollment).value;

    const authoringId = enrollment.surveyAuthoringId;

    return {
      enrollment,
      error: select(enrollmentsError),
      cancelDialog: () => ctx.emit('dialog-closed'),
      loading: select(enrollmentsLoading),
      updateStatus: () => {
        if (enrollment.approvalStatus === EnrollmentStatusEnum.Rejected) {
          emit(
            EnrollmentEditClicked({
              id: enrollment.surveyAuthoringId,
              component: EnrollmentReenrollConceptsDialogVue,
            }),
          );

          return;
        }

        emit(
          EnrollmentUpdateStatusClicked({
            authoringId,
            newApprovalStatus: EnrollmentStatusEnum.Rejected,
          }),
        );
      },
    };
  },
});
