


























import { checkupsMostRecent } from '@/+state/checkups/projections';
import BlueCard from '@/shared/components/BlueCard.vue';
import EmptyState from '@/shared/components/EmptyState.vue';
import StatusIndicatorIcon from '@/shared/components/StatusIndicatorIcon.vue';
import { select } from '@conversa/reflex';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: { BlueCard, StatusIndicatorIcon, EmptyState },
  setup() {
    return {
      checkups: select(checkupsMostRecent),
      empty: 'The patient does not have any recent chats',
    };
  },
});
