import { handle } from '@/+state';
import { ContextInitialized } from '@/+state/misc/events';
import { handleApiError } from '@/shared/api/handle-api-error';
import { FetchProviderFeaturesRequested } from './events';

export const initializeProviderFeaturesHandler = handle(
  [ContextInitialized],
  ({ core }) => {
    const capabilitiesStore = core.stores.capabilitiesStore;
    const patientDetailsStore = core.stores.patientsDetailStore;
    const providerFeaturesStore = core.stores.providerFeaturesStore;

    providerFeaturesStore.providerCode = patientDetailsStore.data.providerCode;
    Object.assign(
      providerFeaturesStore.features,
      capabilitiesStore.capabilities.features,
    );
  },
);

export const fetchProviderFeaturesHandler = handle(
  [FetchProviderFeaturesRequested],
  async ({ core, plugins }) => {
    const store = core.stores.providerFeaturesStore;

    try {
      store.loading = true;
      store.error = null;

      const data = await plugins.api.getProviderFeatures(
        core.event.payload.providerCode,
      );

      store.providerCode = core.event.payload.providerCode;
      Object.assign(store.features, data);
    } catch (error) {
      handleApiError(store, error);
    } finally {
      store.loading = false;
    }
  },
);

export const providerFeaturesHandler = [
  fetchProviderFeaturesHandler,
  initializeProviderFeaturesHandler,
];
