import { handle } from '@/+state';
import { handleApiError } from '@/shared/api/handle-api-error';
import { PatientEdited } from '../patient-details/events';

export const fetchCapabilitiesHandler = handle(
  [PatientEdited],
  async ({ core, plugins }) => {
    const store = core.stores.capabilitiesStore;

    try {
      store.loading = true;
      store.error = null;

      store.capabilities = await plugins.api.getCapabilities();
    } catch (error) {
      handleApiError(store, error);
    } finally {
      store.loading = false;
    }
  },
);

export const capabilitiesHandler = [fetchCapabilitiesHandler];
