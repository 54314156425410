









































import { BannerClose } from '@/+state/banner/events';
import { bannerData, bannerIsOpen } from '@/+state/banner/projections';
import { emit, select } from '@conversa/reflex';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  setup() {
    return {
      banner: select(bannerData),
      bannerIsOpen: select(bannerIsOpen),
      bannerClose: () => emit(BannerClose()),
    };
  },
});
