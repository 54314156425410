import { ErrorState } from '@/shared/models';
import { store } from '@conversa/reflex';
export interface MiscState {
  loading: unknown[];
  error: ErrorState;
}

export const miscInitialState: MiscState = {
  loading: [],
  error: null,
};

export const miscStore = store(miscInitialState);
