import { handle } from '@/+state';
import { ContextInitialized } from '@/+state/misc/events';
import { handleApiError } from '@/shared/api/handle-api-error';
import { CompletedChatsTablePageUpdated } from './events';

export const getCheckupsHandler = handle(
  [CompletedChatsTablePageUpdated, ContextInitialized],
  async ({ core, plugins }) => {
    const store = core.stores.checkupsStore;
    let loadingTimeout;

    store.loading = false;
    store.renderComponent = false;
    store.error = null;

    try {
      loadingTimeout = setTimeout(() => {
        store.renderComponent = true;
        store.loading = true;
      }, 1000);

      if (core.event.type === CompletedChatsTablePageUpdated.type) {
        store.tableOptions.page = core.event.payload.page;
      }

      const options = {
        page: store.tableOptions.page,
        limit: store.tableOptions.itemsPerPage,
        sortDir: store.tableOptions.sortDir,
      };

      const res = await plugins.api.getCheckups(options);

      if (core.event.type === ContextInitialized.type) {
        store.initialData = res.data;
      }

      store.data = res.data;
      store.count = res.count;
    } catch (error) {
      handleApiError(store, error);
    } finally {
      clearTimeout(loadingTimeout);
      store.renderComponent = true;
      store.loading = false;
    }
  },
);

export const checkupsHandlers = [getCheckupsHandler];
