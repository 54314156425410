import { render, staticRenderFns } from "./EnrollmentDetails.vue?vue&type=template&id=1561dad3&scoped=true&"
import script from "./EnrollmentDetails.vue?vue&type=script&lang=ts&"
export * from "./EnrollmentDetails.vue?vue&type=script&lang=ts&"
import style0 from "./EnrollmentDetails.vue?vue&type=style&index=0&id=1561dad3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1561dad3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
installComponents(component, {VBtn,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VOverlay})
