export const monthFullNameDayYearNoLeadingZeros = 'MMMM d, yyyy';
export const monthShortNameDayYearLeadingZeros = 'MMM dd, yyyy';
export const monthDayYearLeadingZeros = 'MM/dd/yyyy';
export const monthDayYearLeadingZeros12hTime = 'MM/dd/yyyy h:mm aaa';
export const yearMonthDayLeadingZeros = 'yyyy-MM-dd';
export const monthDayYearLeadingZerosHyphenated = 'MM-dd-yyyy';
export const dayOfWeekMonthDayOfMonth = 'EEEE MMMM do';
export const mdyLeadZerosWSlash = 'MM/DD/YYYY';
export const dmyLeadZerosWSlash = 'DD/MM/YYYY';
export const ymdLeadZerosWSlash = 'YYYY/MM/DD';
export const mdyLeadZerosWDash = 'MM-DD-YYYY';
export const ymdLeadZerosWDash = 'YYYY-MM-DD';
