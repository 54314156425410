export const noDataIndicator = '--';
export const defaultAlertMessage =
  'An unexpected error occurred. Please refresh the page to try again. If this persists, please contact support.';
export const content = {
  en: {
    language: {
      en: 'English',
      es: 'Spanish',
    },
    activeCaregiver: {
      t: 'Yes',
      f: 'No',
    },
    patientStatus: {
      in: 'Opted-In',
      out: 'Opted-Out',
    },
  },
  es: {
    language: {
      en: 'Inglés',
      es: 'Español',
    },
    activeCaregiver: {
      t: 'Sí',
      f: 'No',
    },
  },
};
