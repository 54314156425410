



























import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from '@vue/composition-api';
import focusableSelectors from 'focusable-selectors';
import { DialogTitleSize } from './dialog-title-size';

export default defineComponent<{
  closable?: boolean;
  fullscreen?: boolean;
  enableClose?: boolean;
  dialogTitle: string;
  dialogTitleSize: DialogTitleSize;
  dialogDescription?: string;
  theme?: string;
}>({
  emits: ['dialog-closed'],
  props: {
    closable: { type: Boolean, default: true },
    fullscreen: { type: Boolean, default: false },
    enableClose: { type: Boolean, default: true },
    dialogTitle: { type: String, required: true },
    dialogTitleSize: {
      type: String,
      required: false,
      default: 'large',
    },
    dialogDescription: { type: String, required: false },
    theme: { type: String, required: false },
  },
  setup(props, ctx) {
    const dialogRef = ref();
    let focusableChildren: HTMLElement[] = [];

    /**
     * We `emit` to the closest parent which is the Dialog
     * implementation where the developer can react to a
     * close event per dialog with unique reactions
     *
     * We `parent.$emit` to the DialogContainer, which
     * subsequently $emits as well to the top level Dialog
     * implementation, where reactions to `dialog-closed`
     * events can share common behavior
     *
     * `event` is passed as the event name and payload
     * to make the propagation easier
     */
    const closeDialog = (forceClose = false) => {
      if (props.enableClose || forceClose) {
        const event = 'dialog-closed';
        ctx.emit(event, event);
        ctx.parent.$emit(event, event);
      }
    };

    const getFocusableChildren = () => {
      const selectors = focusableSelectors.join(',');
      return Array.from(
        (dialogRef.value as HTMLElement).querySelectorAll(selectors),
      );
    };

    const dialogTriggerElement = document.activeElement as HTMLElement;

    const onKeyUp = (event: KeyboardEvent) => {
      // `Esc` kept for IE11 until we can drop support that
      if (['Escape', 'Esc'].includes(event.key)) {
        closeDialog(true);
      }
    };

    onMounted(() => {
      (dialogRef.value as HTMLElement).focus();
      focusableChildren = getFocusableChildren();
      document.addEventListener('keyup', onKeyUp);
    });
    onBeforeUnmount(() => {
      dialogTriggerElement.focus();
      document.removeEventListener('keyup', onKeyUp);
    });

    const onTab = (event: KeyboardEvent) => {
      event.stopPropagation();
      const currentIndex = focusableChildren.indexOf(
        document.activeElement as HTMLElement,
      );
      const lastIndex = focusableChildren.length - 1;
      if (event.shiftKey && currentIndex === 0) {
        focusableChildren[lastIndex].focus();
        event.preventDefault();
      } else if (!event.shiftKey && currentIndex === lastIndex) {
        focusableChildren[0].focus();
        event.preventDefault();
      }
    };

    return {
      dialogRef,
      onClose: closeDialog,
      onTab,
    };
  },
});
