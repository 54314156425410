














import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  props: ['dialog', 'opts'],
  emits: ['dialog-closed'],
  setup(props) {
    return {
      open: computed(() => !!props.dialog),
      closable: computed(() =>
        props.opts?.closable !== undefined ? props.opts.closable : true,
      ),
      fullscreen: computed(() =>
        props.opts?.fullscreen !== undefined ? props.opts.fullscreen : false,
      ),
      theme: computed(() =>
        props.opts?.theme !== undefined ? props.opts.theme : null,
      ),
    };
  },
});
