










import { patientDetailStatus } from '@/+state/patient-details/projections';
import PatientStatusUpdateDialogVue from '@/dialogs/patient-details/PatientStatusUpdateDialog.vue';
import { DialogRef } from '@conversa/bedazzled/src/dialog';
import { select } from '@conversa/reflex';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  setup() {
    const dialog = DialogRef();

    return {
      optedOut: select(patientDetailStatus),
      statusClicked: () => dialog.open(PatientStatusUpdateDialogVue),
    };
  },
});
