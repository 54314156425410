import { project } from '@/+state';
import { BannerData } from '@/models/banner/BannerState.interface';

export const bannerData = project<BannerData>(
  ({ stores }) => stores.bannerStore.data || ({} as BannerData),
);

export const bannerIsOpen = project<boolean>(
  ({ stores }) => !!stores.bannerStore.data,
);

export const bannerProjections = [bannerData, bannerIsOpen];
