import { PatientSession, SurveySessionsScope } from '@/models/survey-sessions';
import { event, payload } from '@conversa/reflex';

export const SurveySessionsMounted = event(
  '[ component | Survey Sessions ] Mounted',
);
export const SurveySessionsLoaded = event(
  '[ component | Survey Sessions ] Loaded',
  payload<PatientSession[]>(),
);
export const SurveySessionsLoadFailed = event(
  '[ component | Survey Sessions ] Load Failed',
);

export const SurveySessionsUpdateScope = event(
  '[ component | Survey Sessions ] Updating Scope',
  payload<{ scope: SurveySessionsScope }>(),
);

export const SurveySessionsUpdated = event(
  '[ component | Survey Sessions ] Scope Updated',
);

export const SurveySessionPageUpdated = event(
  '[ component | Survey Sessions ] Survey Sessions Page Updated',
  payload<{ page: number }>(),
);
