import { Subject } from 'rxjs';
import { action, payload as pl } from 'ts-action';
import { ofType as oT } from 'ts-action-operators';

export const event = action;

export const payload = pl;

export const ReflexInitialized = event('[ Reflex ] Initialized');

export const reflexSub$ = new Subject<unknown>();

export const events$ = reflexSub$.asObservable();

export const emit = (event: unknown) => reflexSub$.next(event);

export const ofType = oT;
