
















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: ['sectionText', 'path'],
  setup() {
    /* */
  },
});
