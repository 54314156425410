import { handle } from '@/+state';
import { emit } from '@conversa/reflex';
import { BannerClose, BannerDataUpdated } from './events';

export const bannerClose = handle([BannerClose], ({ core }) => {
  if (core.stores.bannerStore.timeout)
    clearTimeout(core.stores.bannerStore.timeout);
  core.stores.bannerStore.data = null;
});

export const bannerDataUpdated = handle([BannerDataUpdated], ({ core }) => {
  if (core.stores.bannerStore.timeout)
    clearTimeout(core.stores.bannerStore.timeout);

  core.stores.bannerStore.data = {
    ...core.stores.bannerStore.data,
    ...(core.event.payload || {}),
  };

  core.stores.bannerStore.timeout = (setTimeout as typeof window.setTimeout)(
    emit,
    4000,
    BannerClose(),
  );
});

export const bannerHandlers = [bannerClose, bannerDataUpdated];
