import { project } from '@/+state';
import {
  DemographicsCapabilitiesDto,
  FeaturesCapabilitiesDto,
} from '../+plugins/responses/context-response';

export const loadingCapabilities = project<boolean>(
  ({ stores }) => stores.capabilitiesStore.loading,
);

export const demographicCapabilities = project<DemographicsCapabilitiesDto>(
  ({ stores }) => stores.capabilitiesStore.capabilities.demographics,
);

export const featureCapabilities = project<FeaturesCapabilitiesDto>(
  ({ stores }) => stores.capabilitiesStore.capabilities.features,
);

export const capabilitiesProjections = [
  loadingCapabilities,
  demographicCapabilities,
  featureCapabilities,
];
