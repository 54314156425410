import subYears from 'date-fns/subYears';
import addYears from 'date-fns/addYears';

import { isDateValid } from './utils';
import {
  dmyLeadZerosWSlash,
  mdyLeadZerosWDash,
  mdyLeadZerosWSlash,
  ymdLeadZerosWSlash,
} from '@/constants/dateTime';
import moment from 'moment';

const date422WSlash = new RegExp('^([0-9]{4}/[0-9]{2}/[0-9]{2})?$');
const date224WSlash = new RegExp('^([0-9]{2}/[0-9]{2}/[0-9]{4})?$');
const date224WDash = new RegExp('^([0-9]{2}-[0-9]{2}-[0-9]{4})?$');

function dateFormatRule(date: string, dateFormat: string): boolean | string {
  if (date === '') return true;
  const invalidStr = `Date must be in the format of ${dateFormat}`;
  switch (dateFormat) {
    case ymdLeadZerosWSlash:
      return date422WSlash.test(date) || invalidStr;
    case mdyLeadZerosWSlash:
    case dmyLeadZerosWSlash:
      return date224WSlash.test(date) || invalidStr;
    default:
      return (
        date224WDash.test(date) ||
        `Date must be in the format of ${mdyLeadZerosWDash}`
      );
  }
}

function dateRangeRule(
  date: string,
  dateFormat: string,
  minNumYears: number,
  maxNumYears: number,
): boolean | string {
  if (date === '') return true;
  const isIso = date.split('-').length > 1;
  let d1: string;

  if (!isIso) d1 = moment(date, dateFormat).toISOString();
  else d1 = date;

  if (new Date(d1) < subYears(new Date(), minNumYears))
    return `Please enter a date within the last ${minNumYears} years`;
  if (new Date(d1) > addYears(new Date(), maxNumYears))
    return `Please enter a date within the next ${maxNumYears} years`;

  return true;
}

export function dateValidRule(
  date: string,
  dateFormat: string,
  minNumYears = 130,
  maxNumYears = 130,
): boolean | string {
  if (date === '') return true;
  let result: boolean | string = 'Unknown date formatting error';

  result = dateFormatRule(date, dateFormat);
  if (result === true)
    result = dateRangeRule(date, dateFormat, minNumYears, maxNumYears);
  if (result === true)
    result = isDateValid({ date, dateFormat }) || 'Please enter a valid date';

  return result;
}
