import { Observable, Subscription } from 'rxjs';
import { ActionCreator, ActionType } from 'ts-action';
import { ofType } from 'ts-action-operators';
export interface Core<Event, Stores> {
  event: Event;
  events$: Observable<any>;
  stores: Stores;
}

/**
 * This is the base creator for a handler
 * A handler will take N events and setup a callback
 * We provide a set of core considerations as well as
 * plugins to the callback
 *
 * This does not get used directly - the handle method
 * wraps this to reduce the boiler plate of having to pass events/plugins/stores
 * as generics to every handler
 *
 * @export
 * @template Events
 * @template Plugins
 * @template Stores
 * @param {Events} events
 * @param {(params: {
 *     core: Core<ActionType<Events[number]>, Stores>;
 *     plugins: Plugins;
 *   }) => void} fn
 * @returns {{
 *   fn: (params: { - This gets returned to make unit testin easier
 *     core: Core<ActionType<Events[number]>, Stores>;
 *     plugins: Plugins;
 *   }) => void;
 *   register: (
 *     emit: (event: any) => void,
 *     events$: Observable<any>,
 *     plugins: Plugins,
 *     stores: Stores,
 *   ) => Subscription;
 * }}
 */
export function createHandler<Events extends ActionCreator[], Plugins, Stores>(
  events: Events,
  fn: (params: {
    core: Core<ActionType<Events[number]>, Stores>;
    plugins: Plugins;
  }) => void,
): {
  fn: (params: {
    core: Core<ActionType<Events[number]>, Stores>;
    plugins: Plugins;
  }) => void;
  events: Events;
  register: (
    events$: Observable<any>,
    plugins: Plugins,
    stores: Stores,
  ) => Subscription;
} {
  return {
    fn,
    events,
    register: (events$: Observable<any>, plugins: Plugins, stores: Stores) => {
      return events$.pipe(ofType(...events)).subscribe(event => {
        fn({
          plugins,
          core: {
            event,
            events$,
            stores,
          },
        });
      });
    },
  };
}

/**
 * We wrap createHandler here and need to
 * use this to pass Plugins and Stores in
 * for proper typing, but to avoid having to pass
 * Plugins and Stores to every handler
 *
 * @export
 * @template Plugins
 * @template Stores
 * @returns {<Events extends ActionCreator[]>(
 *   events: Events,
 *   fn: (params: {
 *     core: Core<ActionType<Events[number]>, Stores>;
 *     plugins: Plugins;
 *   }) => void,
 * ) => ReturnType<typeof createHandler>}
 */
export function handler<Plugins, Stores>(): <Events extends ActionCreator[]>(
  events: Events,
  fn: (params: {
    core: Core<ActionType<Events[number]>, Stores>;
    plugins: Plugins;
  }) => void,
) => ReturnType<typeof createHandler> {
  return function h<Events extends ActionCreator[]>(events, fn) {
    return createHandler<Events, Plugins, Stores>(events, fn);
  };
}
