import { event, payload } from '@conversa/reflex';
import { PatientUpdatePayload } from '@/shared/patient-update-payload.interface';
import { ErrorState } from '@/shared/models/error-state.interface';

export const PatientStatusChangeRequested = event(
  '[ component | PatientStatusUpdateDialog ] Status Change Requested',
  payload<{ optedOut: boolean }>(),
);

export const EditPatientDialogSave = event(
  '[ component | EditPatientDialogContainer ] Save',
  payload<PatientUpdatePayload>(),
);

export const PatientEdited = event(
  '[ api | smrfi/patient/:id ] Patient Edited',
);

export const EditPatientFailed = event(
  '[ api | smrfi/patient/:id ] Edit Patient Failed',
  payload<{
    payload: PatientUpdatePayload;
    error: ErrorState;
  }>(),
);

export const PatientRefreshed = event(
  '[ api | smrfi/patient ] Patient Refreshed',
);

export const ResendChatClicked = event(
  '[ component | ResendChatDialog ] Resend Chat Clicked',
);
