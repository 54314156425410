














import SectionHeader from '@/shared/components/SectionHeader.vue';
import { defineComponent } from '@vue/composition-api';
import RecentChats from '../checkups/RecentChats.vue';
import EnrollmentsList from '../enrollments/EnrollmentsList.vue';
import ActivitySummary from './ActivitySummary.vue';
import ScheduledChats from './ScheduledChats.vue';

export default defineComponent({
  components: {
    EnrollmentsList,
    ScheduledChats,
    ActivitySummary,
    SectionHeader,
    RecentChats,
  },
});
