



























import { patientDetailStatus } from '@/+state/patient-details/projections';
import EnrollmentProgramListVue from '@/dialogs/enrollments/EnrollmentProgramList.vue';
import EditPatientDialogContainer from '@/dialogs/patient-details/EditPatientDialogContainer.vue';
import PatientStatusUpdateDialogVue from '@/dialogs/patient-details/PatientStatusUpdateDialog.vue';
import ResendChatDialogVue from '@/dialogs/patient-details/ResendChatDialog.vue';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';
import { select } from '@conversa/reflex';
import { defineComponent } from '@vue/composition-api';
import { providerFeaturesRestrictEnrollment } from '@/+state/capabilities/provider-features/projections';

export default defineComponent({
  setup() {
    return {
      optInOut: select(patientDetailStatus),
      optInOutClick: () =>
        DialogInstance.open(PatientStatusUpdateDialogVue, {}),
      resendClick: () => DialogInstance.open(ResendChatDialogVue, {}),
      enrollmentClick: () => DialogInstance.open(EnrollmentProgramListVue, {}),
      patientInformationClick: () =>
        DialogInstance.open(EditPatientDialogContainer, { fullscreen: true }),
      restrictEnrollment: select(providerFeaturesRestrictEnrollment),
    };
  },
});
