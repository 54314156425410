import { EnrollmentStatusEnum } from '@/shared/models';
import { EnrollmentAnswer } from '@/shared/models/enrollment-answer.type';
import { event, payload } from '@conversa/reflex';
import { VueConstructor } from 'vue';

export const EnrollmentEditClicked = event(
  '[ component | EnrollmentDetails ] Enrollment Edit Button Clicked',
  payload<{
    id: number;
    component: VueConstructor<Vue>;
  }>(),
);

export const EnrollmentSubmitted = event(
  '[ component | EnrollmentCreateDialog ] Enrollment Submitted',
  payload<{
    programId: number;
    enrollmentQuestionAnswers: EnrollmentAnswer[];
  }>(),
);

export const PatientEnrollmentSuccessful = event(
  '[ api | post patient/enrollments ] Enrollment Created',
);

export const EnrollmentUpdateSubmitted = event(
  '[ component | EnrollmentDetailsDialog ] Update Submitted',
  payload<{
    programId: number;
    enrollmentQuestionAnswers: EnrollmentAnswer[];
  }>(),
);

export const EnrollmentDetailsUnmounted = event(
  '[ component | EnrollmentDetailsDialogContainer ] Enrollment Details Dialog Unmounted',
);

export const EnrollmentChangeStatusClicked = event(
  '[ component | EnrollmentsList ] Unenroll Reenroll Button Clicked',
  payload<{ id: number }>(),
);

export const EnrollmentUpdateStatusClicked = event(
  '[ component | EnrollmentStatusDialogContainer ] Update Status Button Clicked',
  payload<{
    authoringId: number;
    newApprovalStatus: EnrollmentStatusEnum;
    enrollmentAnswers?: EnrollmentAnswer[];
  }>(),
);

export const EnrollmentClearSelected = event(
  '[ handler | clearSelectedEnrollment ] Clearing selectedEnrollment',
);

export const EnrollmentChangeStatusSucceeded = event(
  '[ api | patch patients/:id/enrollments/:survey_authoring_id/status ] Enrollment Change Status Succeded',
);

export const UnenrolledPatientLoaded = event(
  '[ handler | getEnrollmentsHandler ] Unenrolled Patient Loaded',
);

export const SurveySelected = event(
  '[ component | EnrollmentProgramList ] Program Selected',
  payload<{ id: number }>(),
);
