import { project } from '@/+state';
import {
  PatientSessionProjection,
  SurveySessionsScope,
} from '@/models/survey-sessions';
import { formatToSurveySessionProjection } from '@/shared/utils';
import { TABLE_CONFIG } from '@conversa/bedazzled/src/constants/tableConfig';
import { select } from '@conversa/reflex';

export const surveySessionsInScope = project<PatientSessionProjection[]>(
  ({ stores }) => {
    return stores.surveySessionsStore[stores.surveySessionsStore.scope].map(
      session => {
        return formatToSurveySessionProjection(
          session,
          stores.capabilitiesStore.capabilities.international.datetimeFormat,
        );
      },
    );
  },
);

export const surveySessionsPreviewList = project<PatientSessionProjection[]>(
  ({ stores }) => {
    const data: PatientSessionProjection[] = [];
    for (const session of stores.surveySessionsStore.future) {
      if (data.length === 3) break;

      data.push(
        formatToSurveySessionProjection(
          session,
          stores.capabilitiesStore.capabilities.international.datetimeFormat,
        ),
      );
    }

    return data;
  },
);

export const surveySessionsCount = project<number>(({ stores }) =>
  stores.surveySessionsStore[stores.surveySessionsStore.scope].length >
  (TABLE_CONFIG.scheduledChats.numRows || 10)
    ? Math.ceil(
        stores.surveySessionsStore[stores.surveySessionsStore.scope].length /
          (TABLE_CONFIG.scheduledChats.numRows || 10),
      )
    : 1,
);

export const surveySessionsPage = project<number>(
  ({ stores }) => stores.surveySessionsStore.page,
);

export const surveySessionsScope = project<SurveySessionsScope>(
  ({ stores }) => stores.surveySessionsStore.scope,
);

export const surveySessionsIsLoading = project<boolean>(
  ({ stores }) => stores.surveySessionsStore.loading,
);

export const surveySessionsNextChat = project<PatientSessionProjection>(() => {
  return select(surveySessionsPreviewList).value[0];
});

export const surveySessionsProjections = [
  surveySessionsInScope,
  surveySessionsPreviewList,
  surveySessionsCount,
  surveySessionsPage,
  surveySessionsScope,
  surveySessionsIsLoading,
  surveySessionsNextChat,
];
