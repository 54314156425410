




































import { SurveyConceptType } from '@conversa/bedazzled/src/models/survey.interface';
import { DIALOG_PROPS } from '@conversa/bedazzled/src/dialog';
import {
  defineComponent,
  reactive,
  ref,
  UnwrapRef,
} from '@vue/composition-api';
import Vue, { VueConstructor } from 'vue';
import { ErrorState } from '@conversa/bedazzled/src/models/error-state.interface';
import DatePickerVue from '@/components/inputs/DatePicker.vue';
import EnrollmentDetailsDialog from '@conversa/bedazzled/src/dialog/enrollments/EnrollmentDetailsDialog.vue';
import { capabilitiesStore } from '@/+state/capabilities/store';
import { ymdLeadZerosWDash } from '@/constants/dateTime';

// required to import due to vuetify not auto importing
import { Program } from '@/shared/models';
import { dateValidRule } from '@/shared/date-format-rule';
import { ProgramConcept } from '../../shared/models';
import moment from 'moment';

interface LocalConcept {
  id: string;
  component: VueConstructor<Vue> | string;
  required: boolean;
  content?: string;
  value?: string | string[];
  options?: { value: number; content: string }[];
  text?: string;
  type?: SurveyConceptType;
  placeholder?: string;
  legend?: string;
  multiple?: boolean;
}

export default defineComponent<{
  cancelButtonCopy: string;
  error: ErrorState;
  loading: boolean;
  program: Program;
  successButtonCopy: string;
  createEnrollment: boolean;
  title: string;
}>({
  props: [
    'cancelButtonCopy',
    'error',
    'loading',
    'program',
    'successButtonCopy',
    'loadingCopy',
    'createEnrollment',
    'title',
    ...DIALOG_PROPS,
  ],
  emits: ['back-clicked', 'cancel-clicked', 'enroll'],
  components: {
    EnrollmentDetailsDialog,
  },
  setup(props, context) {
    const inputMap = {
      mult: 'v-select',
      checks: 'v-select',
      email: 'v-text-field',
      float: 'v-text-field',
      int: 'v-text-field',
      result: 'v-text-field',
      text: 'v-text-field',
      date: DatePickerVue,
    };

    // For date concepts (no time), we'll need to strip the time chars off
    // of the datetimeFormat string
    const dateFormat = capabilitiesStore
      .inject()
      .capabilities.international.datetimeFormat.split(' ', 1)[0];

    function validDate(date: string, dateFormat: string): boolean {
      return dateValidRule(date, dateFormat) === true;
    }

    const rules = {
      required: v => !!v || 'This field is required',
      requiredMult: v =>
        (v !== null && v !== undefined) || 'This field is required',
      requiredChecks: v => !!v?.length || 'This field is required',
      phoneLength: v =>
        (v && v.replace(/\D/g, '').length == 10) ||
        'Phone Number must be 10 digits.',
      emailVal: v =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v,
        ) || 'Must be a valid email address.',
      dateValid: date => dateValidRule(date, dateFormat),
    };

    /**
     * Decided against doing this in a projection
     * because we wanted Reactive objects that we could
     * manipulate for the inputs
     */
    const concepts: UnwrapRef<LocalConcept>[] = props.program.concepts.map(
      (concept, index) => {
        const conceptRules = [];
        let conceptId = concept.conceptIdentifier;

        switch (concept.typeCode) {
          case 'email':
            conceptRules.push(rules.emailVal);
            break;
          case 'phone':
            conceptRules.push(rules.phoneLength);
            break;
          case 'date':
            conceptRules.push(rules.dateValid);
            break;
        }
        if (concept.required) {
          switch (concept.typeCode) {
            case 'checks':
              conceptRules.push(rules.requiredChecks);
              break;
            case 'mult':
              conceptRules.push(rules.requiredMult);
              break;
            default:
              conceptRules.push(rules.required);
          }
        }
        if (!conceptId) {
          conceptId += `:${index}`;
        }

        if (concept.typeCode !== 'mult' && concept.typeCode !== 'checks') {
          return reactive({
            component: inputMap[concept.typeCode] || 'v-text-field',
            content: concept.text,
            id: conceptId,
            required: concept.required,
            text:
              concept.text +
              (concept.required ? ' (required)' : '') +
              (concept.typeCode == 'date' ? ` (${dateFormat})` : ''),
            type:
              (['float', 'int'].includes(concept.typeCode) && 'number') ||
              concept.typeCode,
            step: concept.typeCode === 'float' && 'any',
            value: concept.value,
            filled: true,
            name: `${concept.conceptIdentifier}:name`,
            rules: conceptRules,
            disabled: props.loading,
            dateFormat: ['date'].includes(concept.typeCode)
              ? dateFormat
              : undefined,
          });
        }

        return reactive({
          id: conceptId,
          type: concept.typeCode,
          text: concept.text + (concept.required ? ' (required)' : ''),
          required: concept.required,
          placeholder: 'Please Select',
          component: inputMap[concept.typeCode] || 'v-select',
          multiple: concept.typeCode === 'checks',
          items: concept.options,
          'item-text': 'text',
          'item-value': 'conceptIdentifier',
          filled: true,
          name: `${concept.conceptIdentifier}:name`,
          value:
            concept.value || (props.createEnrollment == true ? undefined : ''),
          rules: conceptRules,
          disabled: props.loading,
        });
      },
    );

    const topLevelConcepts = props.program.concepts.reduce((acc, curr) => {
      acc[curr.conceptIdentifier] = curr.value || null;
      return acc;
    }, {});

    const moreRequiredConceptsNeeded: (conceptList) => boolean = function<
      T extends LocalConcept & ProgramConcept
    >(conceptList: T[]): boolean {
      return conceptList.some(concept => {
        const typeCode = concept.typeCode ?? concept.type;
        let conceptValueString: string | undefined =
          (concept?.value as string) || concept?.value?.[0];

        switch (typeCode) {
          case 'checks':
            return concept.required && !concept.value?.length;
          case 'mult':
            return (
              concept.required &&
              (concept.value === null || concept.value === undefined)
            );
          case 'date':
            if (conceptValueString?.split('-').length > 1)
              conceptValueString = moment(conceptValueString).format(
                dateFormat,
              );

            return (
              (concept.required && !conceptValueString) ||
              (conceptValueString && !validDate(conceptValueString, dateFormat))
            );
          default:
            return concept.required && !concept.value;
        }
      });
    };

    const disableEnrollButton = moreRequiredConceptsNeeded(concepts);
    const isFormValid = ref(false);
    const onIsFormValid = val => (isFormValid.value = val);

    return {
      disableEnrollButton,
      isFormValid,
      onIsFormValid,
      programName: props.program.name,
      concepts,
      back: () => context.emit('back-clicked'),
      cancel: () => context.emit('cancel-clicked'),
      clear: (event: { id: string; value: string }) => {
        const rootConcept = props.program.concepts.find(
          c => c.conceptIdentifier === event.id,
        );
        const concept = concepts.find(c => c.id === event.id);
        concept.value = rootConcept.value; // this is utilized by inputs like text

        topLevelConcepts[event.id] = // this is what gets used for the submission
          (concept.type === 'checks' &&
            Array.isArray(event.value) &&
            event.value.reduce(
              (acc, curr) => {
                acc[curr] = 'on';
                return acc;
              }, // 'checks' is special, as of 6/18/21 existing backend code still requires this during a validation step
              {},
            )) ||
          event.value;
      },
      onInputChanged(event: { id: string; value: string }) {
        const concept = concepts.find(c => c.id === event.id);
        concept.value = event.value; // this is utilized by inputs like text

        // For date values the topLevelConcept stores the text that will
        // be saved to the DB. This should be saved in YYYY-DD-MM format
        // regardless of the date format in the org-settting.
        if (
          concept.type == 'date' &&
          event.value !== '' &&
          event.value !== undefined &&
          !/^([0-9]{4}-[0-9]{2}-[0-9]{2})?$/.test(event.value) &&
          validDate(event.value, dateFormat)
        ) {
          topLevelConcepts[event.id] = moment(event.value, dateFormat).format(
            ymdLeadZerosWDash,
          );
        } else topLevelConcepts[event.id] = event.value;

        const result = moreRequiredConceptsNeeded(concepts);
        this.disableEnrollButton = result;
      },
      enroll() {
        if ((this.disableEnrollButton = moreRequiredConceptsNeeded(concepts)))
          return;

        context.emit('enroll', {
          programId: props.program.authoringId,
          enrollmentQuestionAnswers: Object.keys(topLevelConcepts).reduce(
            (acc, curr) => {
              if (topLevelConcepts[curr] === null) return acc;

              if (curr.includes('null')) {
                if (Array.isArray(topLevelConcepts[curr])) {
                  topLevelConcepts[curr].map(item => {
                    acc.push({
                      concept: item,
                      value: '',
                    });
                  });
                } else
                  acc.push({
                    concept: topLevelConcepts[curr],
                    value: '',
                  });
              } else {
                acc.push({
                  concept: curr,
                  value: topLevelConcepts[curr],
                });
              }
              return acc;
            },
            [],
          ),
        });
      },
    };
  },
});
