import { PatientsDetailSummaryEnrollmentsState } from '@/models/enrollments/state.interface';
import { store } from '@conversa/reflex';

export const patientDetailSummaryEnrollmentsInitialState: PatientsDetailSummaryEnrollmentsState = {
  loading: false,
  error: null,
  enrollments: [],
  surveys: [],
  programs: [],
  selectedProgram: null,
  selectedEnrollment: null,
};

export const patientDetailSummaryEnrollmentsStore = store(
  patientDetailSummaryEnrollmentsInitialState,
);
