import { SurveySessionsState } from '@/models/survey-sessions';
import { store } from '@conversa/reflex';

export const surveySessionsInitialState: SurveySessionsState = {
  error: null,
  future: [],
  loading: false,
  page: 1,
  past: [],
  scope: 'future',
};

export const surveySessionsStore = store(surveySessionsInitialState);
