











import EnrollmentCard from './EnrollmentCard.vue';
import { defineComponent } from '@vue/composition-api';
import { EnrollmentProjection } from '@/models/enrollments/enrollments-projection.interface';
import { DialogRef } from '@conversa/bedazzled/src/dialog';
import EnrollmentStatusDialog from '@/dialogs/enrollments/EnrollmentStatusDialogContainer.vue';
import { EnrollmentChangeStatusClicked } from '@/+state/enrollments/events';
import { emit, select } from '@conversa/reflex';
import { enrollments } from '@/+state/enrollments/projections';

export default defineComponent({
  components: { EnrollmentCard },
  setup() {
    const dialog = DialogRef();

    const onStatusClick = ({
      enrollment,
    }: {
      enrollment: EnrollmentProjection;
    }) => {
      emit(EnrollmentChangeStatusClicked({ id: enrollment.surveyId }));
      dialog.open(EnrollmentStatusDialog);
    };

    return {
      enrollments: select(enrollments),
      onStatusClick,
    };
  },
});
