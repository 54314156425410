import { CheckupResponsesState } from '@/models/checkup-responses/state.interface';
import { store } from '@conversa/reflex';

export const checkupResponsesInitialState: CheckupResponsesState = {
  loadingCheckupId: {},
  renderComponent: {},
  error: null,
  data: {},
};

export const checkupResponsesStore = store(checkupResponsesInitialState);
