




























import { EnrollmentEditClicked } from '@/+state/enrollments/events';
import { enrollmentsLoading } from '@/+state/enrollments/projections';
import EnrollmentUpdateDialogVue from '@/dialogs/enrollments/EnrollmentUpdateDialog.vue';
import { EnrollmentProjection } from '@/models/enrollments/enrollments-projection.interface';
import { InlineLoader } from '@conversa/bedazzled/src/loaders';
import { emit, select } from '@conversa/reflex';
import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  components: { InlineLoader },
  props: {
    enrollment: {
      required: true,
      type: Object as PropType<EnrollmentProjection>,
    },
  },
  setup({ enrollment }) {
    return {
      loading: select(enrollmentsLoading),
      onEditClick: () => {
        emit(
          EnrollmentEditClicked({
            id: (enrollment as EnrollmentProjection).surveyId,
            component: EnrollmentUpdateDialogVue,
          }),
        );
      },
    };
  },
});
