import { inject, provide, reactive, UnwrapRef } from '@vue/composition-api';

/**
 *
 *
 * @export
 * @template S
 * @param {S} state
 * @returns {{
 *   stateKey: symbol;
 *   state: S;
 *   register: () => void;
 *   inject: () => UnwrapRef<S>;
 * }}
 */
export function store<S>(
  state: S,
): {
  stateKey: symbol;
  state: S;
  register: () => void;
  inject: () => UnwrapRef<S>;
} {
  const stateKey = Symbol();
  return {
    stateKey,
    state,
    register: () => {
      provide(stateKey, reactive(JSON.parse(JSON.stringify(state))));
    },
    inject: () => {
      const store = inject<UnwrapRef<S>>(stateKey);

      if (!store) {
        throw new Error(
          `No store found by that name to inject: ${String(stateKey)}`,
        );
      }

      return store;
    },
  };
}
