import { project } from '@/+state';
import { Provider } from '@/models/providers/provider.interface';
import { SelectOptions } from '@/shared/models/select-options.interface';

export const loadingProviders = project<boolean>(
  ({ stores }) => stores.providersStore.loadingProviders,
);

export const providers = project<Provider[]>(
  ({ stores }) => stores.providersStore.providers,
);

export const providersForSelect = project<SelectOptions[]>(({ stores }) => {
  const providers = (stores.providersStore.providers || []).filter(p => {
    return p.active && p.role === 'provider';
  });
  return providers.map(provider => ({
    value: provider.providerCode,
    content: `${provider.firstName} ${provider.lastName}`,
  }));
});

export const providersProjections = [
  loadingProviders,
  providers,
  providersForSelect,
];
