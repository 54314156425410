







import { defineComponent } from '@vue/composition-api';
import { HoverLinkCardProps } from './hover-link-card-props.interface';

export default defineComponent<HoverLinkCardProps>({
  props: ['to', 'title', 'description'],
});
