





































import { ActionCard, ExpandableCard } from '@conversa/bedazzled/src/cards';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DIALOG_PROPS,
} from '@conversa/bedazzled/src/dialog';
import { InlineLoader } from '@conversa/bedazzled/src/loaders';
import { computed, defineComponent, reactive } from '@vue/composition-api';

export default defineComponent({
  props: ['loading', 'surveys', 'cancel', ...DIALOG_PROPS],
  emits: ['survey-selected', 'cancel-dialog'],
  components: {
    Dialog,
    DialogContent,
    DialogActions,
    InlineLoader,
    ActionCard,
    ExpandableCard,
  },
  setup(props, ctx) {
    const isOpenMap = reactive(
      props.surveys.reduce((acc, curr) => {
        acc[curr.id] = false;
        return acc;
      }, {}),
    );

    return {
      surveySelected: surveyId => ctx.emit('survey-selected', surveyId),
      cancelDialog: () => ctx.emit('cancel-dialog'),
      dialogDescription: computed(() =>
        props.surveys.length
          ? 'Available programs for enrollment.'
          : 'No available programs for enrollment.',
      ),
      isOpenMap,
      toggleOpen: surveyId => (isOpenMap[surveyId] = !isOpenMap[surveyId]),
    };
  },
});
