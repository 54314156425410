import { TABLE_CONFIG } from '@/constants/tableConfig';
import { CheckupsState } from '@/models/checkups/state.interface';
import { store } from '@conversa/reflex';

export const checkupsInitialState: CheckupsState = {
  loading: false,
  renderComponent: true,
  error: null,
  data: [],
  initialData: [],
  count: 0,
  tableOptions: {
    page: 1,
    itemsPerPage: TABLE_CONFIG.checkups.tableRows,
    sortDir: TABLE_CONFIG.checkups.sortDir,
  },
};

export const checkupsStore = store(checkupsInitialState);
