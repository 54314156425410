
































import { defineComponent, PropType } from '@vue/composition-api';
import { EnrollmentProjection } from '@/models/enrollments/enrollments-projection.interface';
import EnrollmentDetails from './EnrollmentDetails.vue';
import BlueCard from '@/shared/components/BlueCard.vue';

export default defineComponent({
  components: { EnrollmentDetails, BlueCard },
  props: {
    enrollment: {
      required: true,
      type: Object as PropType<EnrollmentProjection>,
    },
  },
  emits: ['status-clicked'],
  setup({ enrollment }, ctx) {
    return {
      onStatusClick: () => ctx.emit('status-clicked', { enrollment }),
    };
  },
});
