import { handle } from '@/+state';
import { handleApiError } from '@/shared/api/handle-api-error';
import { PatientUpdatePayload } from '@/shared/patient-update-payload.interface';
import { DialogInstance } from '@conversa/bedazzled/src/dialog/actions';
import { emit } from '@conversa/reflex';
import {
  EditPatientDialogSave,
  EditPatientFailed,
  PatientEdited,
  PatientRefreshed,
  PatientStatusChangeRequested,
  ResendChatClicked,
} from './events';

export const updatePatientStatusHandler = handle(
  [PatientStatusChangeRequested],
  async ({ core, plugins }) => {
    const store = core.stores.patientsDetailStore;

    try {
      store.statusLoading = true;
      store.error = null;

      /**
       * I'd advise against this pattern
       * Use the response from the backend to dictate
       * what the data will be, this introduces indirection
       * @jason.awbrey
       */
      await plugins.api.updatePatientStatus(core.event.payload.optedOut);
      store.data.optedOut = core.event.payload.optedOut;

      DialogInstance.close();
    } catch (error) {
      handleApiError(store, error);
    } finally {
      store.statusLoading = false;
    }
  },
);

// this removes keys with unchanged values so we are only sending what has changed to the api
const filterKeys = (object1, object2): Partial<PatientUpdatePayload> => {
  const keys = Object.keys(object2);
  const ret = {};
  for (const key of keys) {
    if (object1[key] !== object2[key]) {
      ret[key] = object2[key];
    }
  }

  // If the preferred Comms channel changes, backend will require the
  // corresponding field
  if (ret['preferredCommunicationChannel'] === 'email') {
    ret['email'] = object2['email'];
  } else if (ret['preferredCommunicationChannel'] === 'sms') {
    ret['mobilePhone'] = object2['mobilePhone'];
  }

  return ret;
};

export const patientEdited = handle(
  [EditPatientDialogSave],
  async ({ core, plugins }) => {
    const store = core.stores.patientsDetailStore;
    const { payload } = core.event;

    // filter out any unchanged values
    const patientUpdateData = filterKeys(store.data, payload);

    try {
      store.profileLoading = true;
      store.error = null;

      /**
       * I'd advise against this pattern
       * Use the response from the backend to dictate
       * what the data will be, this introduces indirection
       * @jason.awbrey
       */
      await plugins.api.patchPatient(patientUpdateData);

      for (const key in patientUpdateData) {
        store.data[key] = patientUpdateData[key];
      }

      emit(PatientEdited());
    } catch (error) {
      handleApiError(store, error);
      emit(
        EditPatientFailed({
          payload: payload,
          error: store.error,
        }),
      );
    } finally {
      store.profileLoading = false;
    }
  },
);

export const resendCommunicationHandler = handle(
  [ResendChatClicked],
  async ({ core, plugins }) => {
    const store = core.stores.patientsDetailStore;

    try {
      store.error = null;
      store.resendChatLoading = true;
      await plugins.api.resendCommunication();
      DialogInstance.close();
    } catch (error) {
      handleApiError(store, error);
    } finally {
      store.resendChatLoading = false;
    }
  },
);

export const refreshPatientDetailsHandler = handle(
  [PatientEdited],
  async ({ core, plugins }) => {
    const store = core.stores.patientsDetailStore;

    try {
      store.error = null;
      store.statusLoading = true;

      const data = await plugins.api.getPatientDetails();

      store.data = data;
      emit(PatientRefreshed());
    } catch (error) {
      handleApiError(store, error);
    } finally {
      store.statusLoading = false;
    }
  },
);

export const patientDetailHandlers = [
  patientEdited,
  refreshPatientDetailsHandler,
  resendCommunicationHandler,
  updatePatientStatusHandler,
];
