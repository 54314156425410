






















import BlueCard from '@/shared/components/BlueCard.vue';
import { ExpandableCard } from '@conversa/bedazzled/src/cards';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: ['survey', 'canEnroll'],
  components: {
    BlueCard,
    ExpandableCard,
  },
  setup(_, ctx) {
    return {
      handleEnroll: surveyId => ctx.emit('handle-enroll', surveyId),
    };
  },
});
