




































import { defineComponent, ref, watch } from '@vue/composition-api';
import moment from 'moment';
import { dateValidRule } from '@/shared/date-format-rule';
import { ymdLeadZerosWDash } from '@/constants/dateTime';

export default defineComponent({
  emits: ['date-updated'],
  props: {
    isoDate: {
      required: true,
      type: String,
    },
    rules: {
      default: () => [],
      type: Array,
    },
    errorMessage: {
      default: '',
      type: String,
    },
    dateFormat: { default: '', type: String },
  },
  setup(props, ctx) {
    const dateOpen = ref(false);
    const readableDate = ref(
      moment.utc(props.isoDate).format(props.dateFormat),
    );

    const emit = val => {
      ctx.emit('date-updated', val);
    };

    watch(
      () => props.isoDate,
      newVal => {
        readableDate.value = moment.utc(newVal).format(props.dateFormat);
      },
    );

    return {
      dateOpen,
      readableDate,
      onTextChange: v => {
        // The EditPatientDialog is expecting YYYY-MM-DD for manually
        // entered date values.
        if (!v || dateValidRule(v, props.dateFormat, 130, 0) !== true) return;
        emit(moment(v, props.dateFormat).format(ymdLeadZerosWDash));
      },
      onDateChange: v => {
        // Value returned by v-date-picker will match YYYY-MM-DD
        emit(v);
        dateOpen.value = false;
      },
    };
  },
});
