import { handle } from '@/+state';
import { ContextInitialized } from '@/+state/misc/events';
import { handleApiError } from '@/shared/api/handle-api-error';
import { ActivityPageUpdated } from './events';

export const fetchActivities = handle(
  [ActivityPageUpdated, ContextInitialized],
  async ({ core, plugins }) => {
    core.stores.activityStore.loading = true;
    core.stores.activityStore.error = null;

    try {
      if (core.event.type === ActivityPageUpdated.type) {
        core.stores.activityStore.tableOptions.page = core.event.payload.page;
      }

      const options = {
        page: core.stores.activityStore.tableOptions.page,
        limit: core.stores.activityStore.tableOptions.itemsPerPage,
      };

      const { data, count } = await plugins.api.getActivities(options);

      if (core.event.type === ContextInitialized.type) {
        core.stores.activityStore.initialData = data;
      }

      core.stores.activityStore.data = data;
      core.stores.activityStore.count = count;
    } catch (err) {
      handleApiError(core.stores.activityStore, err);
    } finally {
      core.stores.activityStore.loading = false;
    }
  },
);

export const activityHandlers = [fetchActivities];
