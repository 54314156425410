import { ActivitiesResponse } from '@/+state/+plugins/responses/activities-response';
import {
  CapabilitiesDto,
  ContextResponse,
  FeaturesCapabilitiesDto,
} from '@/+state/+plugins/responses/context-response';
import { UserNoValidOrgs } from '@/+state/user/events';
import { SurveySessionsScope } from '@/models/survey-sessions';
import { EnrollmentStatusEnum } from '@/shared/models';
import { EnrollmentAnswer } from '@/shared/models/enrollment-answer.type';
import { TableOptionsBackend } from '@/shared/models/table-options';
import { PatientUpdatePayload } from '@/shared/patient-update-payload.interface';
import { emit } from '@conversa/reflex';
import axios from 'axios';
import { Stores } from '../index';
import { UserAccessTokenInvalid } from '../user/events';
import { CheckupResponsesResponse } from './responses/checkup-responses-response';
import { CheckupsResponse } from './responses/checkups-response';
import {
  GetEnrollmentsResponse,
  PatchEnrollmentsResponse,
  PostEnrollmentsResponse,
} from './responses/enrollments-response';
import { PatientResponse } from './responses/patient-response';
import { ProgramResponse } from './responses/program-response';
import { ProgramsResponse } from './responses/programs-response';
import { SessionsResponse } from './responses/sessions-response';
import { UsersResponse } from './responses/users-response';

export class InvalidTokenError extends Error {
  constructor(message) {
    super(message);
    this.name = 'InvalidTokenError';
  }
}

export function isAuthError(
  error: InvalidTokenError | unknown,
): error is InvalidTokenError {
  return error instanceof InvalidTokenError;
}

interface EnrollmentPayload {
  enrollmentQuestionAnswers: EnrollmentAnswer[];
  active: boolean;
  programId?: number;
  reEnroll?: boolean;
}

export const api = (stores: Stores) => {
  const axiosInstance = axios.create();

  axiosInstance.defaults.headers = {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  };

  axiosInstance.interceptors.request.use(function(request) {
    request.headers['Authorization'] = `Bearer ${stores.userStore.accessToken}`;
    return request;
  });

  axiosInstance.interceptors.response.use(
    function(response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      return response;
    },
    function(error) {
      // errors here might not have an HTTP response, such as timeouts
      const { status } = error?.response;

      if (typeof status !== 'undefined') {
        switch (status) {
          case 400:
            if (
              error.response.data?.message ===
              'token references no valid organizations'
            ) {
              emit(UserNoValidOrgs());
              return Promise.reject(
                new InvalidTokenError(error.response.data.message),
              );
            }
            break;
          case 401:
            emit(UserAccessTokenInvalid());
            return Promise.reject(new InvalidTokenError('auth token invalid'));
        }
      }

      return Promise.reject(error);
    },
  );

  return {
    async getActivities(params: TableOptionsBackend) {
      return (
        await axiosInstance.get<ActivitiesResponse>(
          '/api/smrfi/patient/activities',
          {
            params,
          },
        )
      ).data;
    },

    async getContext() {
      return (await axiosInstance.get<ContextResponse>('/api/smrfi/context'))
        .data;
    },

    async getCapabilities() {
      return (
        await axiosInstance.get<CapabilitiesDto>('/api/smrfi/capabilities')
      ).data;
    },

    async getProviderFeatures(providerCode: string) {
      return (
        await axiosInstance.get<FeaturesCapabilitiesDto>(
          `/api/smrfi/capabilities/users/${providerCode}`,
        )
      ).data;
    },

    async getCheckups(params: TableOptionsBackend) {
      return (
        await axiosInstance.get<CheckupsResponse>(
          '/api/smrfi/patient/checkups',
          {
            params,
          },
        )
      ).data;
    },

    async getCheckupResponses(checkupId: number) {
      return (
        await axiosInstance.get<CheckupResponsesResponse>(
          `/api/smrfi/patient/checkups/${checkupId}/responses`,
        )
      ).data;
    },

    async getProgram(programId: number) {
      return (
        await axiosInstance.get<ProgramResponse>(
          `/api/smrfi/programs/${programId}`,
        )
      ).data;
    },

    async getPrograms() {
      return (
        await axiosInstance.get<ProgramsResponse>('/api/smrfi/programs/search')
      ).data;
    },

    async getEnrollments() {
      return (
        await axiosInstance.get<GetEnrollmentsResponse>(
          '/api/smrfi/patient/enrollments',
        )
      ).data;
    },

    async postEnrollments(payload: EnrollmentPayload) {
      return (
        await axiosInstance.post<PostEnrollmentsResponse>(
          '/api/smrfi/patient/enrollments',
          payload,
        )
      ).data;
    },

    async patchEnrollments(enrollmentId: number, payload: EnrollmentPayload) {
      return (
        await axiosInstance.patch<PatchEnrollmentsResponse>(
          `/api/smrfi/patient/enrollments/${enrollmentId}`,
          payload,
        )
      ).data;
    },

    async updateEnrollmentStatus(
      enrollmentId: number,
      approvalStatus: EnrollmentStatusEnum,
      enrollmentAnswers: EnrollmentAnswer[],
    ) {
      return (
        await axiosInstance.post<PostEnrollmentsResponse>(
          `/api/smrfi/patient/enrollments/${enrollmentId}/status`,
          {
            approvalStatus,
            enrollmentAnswers,
          },
        )
      ).data;
    },

    async updatePatientStatus(optedOut: boolean) {
      return (
        await axiosInstance.post('/api/smrfi/patient/status', {
          optedOut,
        })
      ).data;
    },

    async patchPatient(payload: Partial<PatientUpdatePayload>) {
      return (await axiosInstance.patch('/api/smrfi/patient', payload)).data;
    },

    async resendCommunication() {
      return (
        await axiosInstance.post('/api/smrfi/patient/resend_communication')
      ).data;
    },

    async getProviders() {
      return (await axiosInstance.get<UsersResponse>('/api/smrfi/users')).data;
    },

    async getSessions(scope: SurveySessionsScope) {
      return (
        await axiosInstance.get<SessionsResponse>(
          '/api/smrfi/patient/sessions',
          { params: { scope } },
        )
      ).data;
    },

    async getPatientDetails() {
      return (await axiosInstance.get<PatientResponse>('/api/smrfi/patient'))
        .data.data;
    },
  };
};
