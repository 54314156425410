export type EnrollmentStatus =
  | 'approved'
  | 'rejected'
  | 'pending'
  | 'unqualified';

export enum EnrollmentStatusEnum {
  Approved = 'approved',
  Rejected = 'rejected',
  Pending = 'pending',
  Unqualified = 'unqualified',
}
