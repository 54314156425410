import { event } from '@conversa/reflex';

export const UserAccessTokenSet = event(
  '[ component | User ] Access Token Set',
);

export const UserAccessTokenInvalid = event(
  '[ component | User ] Access Token Invalid',
);

export const UserNoValidOrgs = event(
  '[ component | User ] No Valid Organizations',
);
