import Vue from 'vue';
import VueCompositionApi from '@vue/composition-api';
import App from './App.vue';
import router from './router';
import VueTippy from 'vue-tippy/dist/vue-tippy.esm';
import vuetify from '@/plugins/vuetify';
import VueTelInput from 'vue-tel-input';
import './scss/vue-tel-input.scss';

Vue.use(VueCompositionApi);
Vue.config.productionTip = false;
Vue.use(VueTippy);
Vue.use(VueTelInput, {
  mode: 'national',
  defaultCountry: 'US',
  inputOptions: {
    placeholder: 'Phone Number',
  },
  dropdownOptions: {
    showDialCodeInList: true,
    showFlags: true,
  },
});

interface ClickOutside extends HTMLElement {
  clickOutsideEvent(event): void;
}

const htmlElement = document.querySelector('html');

// RE: https://stackoverflow.com/a/42389266
Vue.directive('click-outside', {
  bind: function(el: ClickOutside, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: function(el: ClickOutside) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

Vue.directive('overflow-y-hidden', {
  inserted: () => {
    htmlElement.classList.add('overflow-y-hidden');
  },
  unbind: () => {
    htmlElement.classList.remove('overflow-y-hidden');
  },
});

new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app');
