import {
  computed,
  ComputedRef,
  InjectionKey,
  provide,
} from '@vue/composition-api';

/**
 * createProjection does not get used directly
 * Instead we wrap it with project so we can automate
 * the InjectionKey creation with a Symbol and can
 * simplify the setup of generics
 *
 * @export
 * @template Key
 * @template Projection
 * @template Stores
 * @param {Key} key
 * @param {(params: { stores: Stores }) => Projection} fn
 * @returns {{
 *   key: Key; - required for proper injection with select
 *   fn: (params: { stores: Stores }) => Projection; - returned to make unit testing easier
 *   register: (stores: Stores) => void; - required for registration with Reflex
 * }}
 */
export function createProjection<
  Key extends InjectionKey<ComputedRef<Projection>>,
  Projection,
  Stores
>(
  key: Key,
  fn: (params: { stores: Stores }) => Projection,
): {
  key: Key;
  fn: (params: { stores: Stores }) => Projection;
  register: (stores: Stores) => void;
} {
  return {
    key,
    fn,
    register: (stores: Stores) => {
      const projection = computed(() => fn({ stores }));
      provide(key, projection);
    },
  };
}

/**
 * Wraps createProjection and passes
 * a Symbol for the InjectionKey
 *
 * @export
 * @template S
 * @returns
 */
export function projector<S>() {
  return function projector<T>(projection: (params: { stores: S }) => T) {
    return createProjection<symbol, T, S>(Symbol(), projection);
  };
}
