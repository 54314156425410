











import {
  defineComponent,
  ref,
  computed,
  onMounted,
} from '@vue/composition-api';
import sanitizeHtml from 'sanitize-html';

const sanitizeOptions = {
  allowedTags: ['br', 'hr', 'em', 'strong'],
};

export default defineComponent({
  name: 'ExpandableCard',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      required: true,
    },
    previewLines: {
      type: Number,
      default: 3,
      required: false,
    },
  },
  setup(props) {
    const isCardOpen = ref<boolean>(props.isOpen);

    const makeSafeContent = function(unsafeContent) {
      const unsafeReplacedContent = unsafeContent.replace(/\n/g, '<br />\n');
      return sanitizeHtml(unsafeReplacedContent, sanitizeOptions);
    };

    const contentRef = ref(null);
    const maskRef = ref(null);
    const safeContent = ref(makeSafeContent(props.content));
    const retriggerFlow = ref(false);

    onMounted(() => {
      setTimeout(() => {
        retriggerFlow.value = true;
      }, 50);
    });

    const maskStyleObj = computed(() => {
      const contentHeight =
        (retriggerFlow.value && contentRef.value?.clientHeight) || 0;
      const contentLines = contentHeight / 16; // lines are 16px
      let linesToShow = props.previewLines;

      if (contentLines <= props.previewLines) {
        linesToShow = contentLines;
      }
      return {
        height: isCardOpen.value
          ? `${contentHeight}px`
          : `${linesToShow * 1.25}em`,
      };
    });

    return {
      isCardOpen,
      contentRef,
      maskRef,
      maskStyleObj,
      safeContent,
      toggle: () => (isCardOpen.value = !isCardOpen.value),
    };
  },
});
