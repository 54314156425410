

































import { providerFeaturesAlertingEnabled } from '@/+state/capabilities/provider-features/projections';
import { SurveySelected } from '@/+state/enrollments/events';
import {
  availablePrograms,
  enrollmentsLoading,
  hasEnrollments,
} from '@/+state/enrollments/projections';
import { DialogUnmounted } from '@/+state/misc/events';
import { hasPatientConsent } from '@/+state/patient-details/projections';
import ProgramCard from '@/components/enrollments/ProgramCard.vue';
import { DialogRef, DIALOG_PROPS } from '@conversa/bedazzled/src/dialog';
import EnrollmentProgramListDialog from '@conversa/bedazzled/src/dialog/enrollments/EnrollmentProgramListDialog.vue';
import { emit, select } from '@conversa/reflex';
import { defineComponent, onUnmounted } from '@vue/composition-api';
import EditPatientDialogContainer from '../patient-details/EditPatientDialogContainer.vue';
import EnrollmentCreateDialogVue from './EnrollmentCreateDialog.vue';

export default defineComponent({
  props: [...DIALOG_PROPS],
  components: {
    EnrollmentProgramListDialog,
    ProgramCard,
  },
  setup() {
    const dialog = DialogRef();
    const hasConsent = select(hasPatientConsent).value;

    const nextDialog = hasConsent
      ? EnrollmentCreateDialogVue
      : EditPatientDialogContainer;

    onUnmounted(() =>
      emit(DialogUnmounted({ dialogName: 'EnrollmentProgramList' })),
    );

    return {
      alertingEnabled: select(providerFeaturesAlertingEnabled),
      loading: select(enrollmentsLoading),
      surveys: select(availablePrograms),
      hasEnrollments: select(hasEnrollments),
      selectSurvey: surveyId => {
        emit(SurveySelected({ id: surveyId }));
        dialog.open(nextDialog, {
          fullscreen: true,
          closable: true,
        });
      },
      cancel: () => {
        dialog.close();
      },
    };
  },
});
