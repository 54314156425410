import { inject, reactive } from '@vue/composition-api';
import { Subject } from 'rxjs';
import { Component } from 'vue';

export const DIALOG_TOKEN = Symbol('DIALOG_TOKEN');

export const DIALOG_PROPS = ['closable', 'fullscreen', 'theme'];

export type DialogOpts = {
  closable?: boolean;
  fullscreen?: boolean;
  theme?: string;
  [k: string]: unknown;
};

export type DialogEvent = {
  component: Component;
  opts: DialogOpts;
};

export interface DialogProviderInterface {
  dialog: Component;
  open: (dialogComponentRef: Component, opts?: DialogOpts) => this;
  close: () => this;
  events$: Subject<DialogEvent>;
  opts?: DialogOpts;
}

export const DialogInstance = {
  open: function(dialogComponentRef, opts = {}) {
    this.dialog = dialogComponentRef;
    this.opts = { ...(typeof opts === 'object' ? opts : {}) };

    this.events$.next({ component: dialogComponentRef, opts });

    return this;
  },
  close: function() {
    this.dialog = null;
    this.opts = {};

    this.events$.next({ component: null, opts: {} });

    return this;
  },
  events$: new Subject<DialogEvent>(),
  dialog: null,
  opts: null,
};

export const DialogProvider = () =>
  reactive<DialogProviderInterface>(DialogInstance);

export const DialogRef = () => inject<DialogProviderInterface>(DIALOG_TOKEN);
