import { handler, projector } from '@conversa/reflex';
import { activityStore } from './activity/store';
import { capabilitiesStore } from './capabilities/store';
import { providerFeaturesStore } from './capabilities/provider-features/store';
import { checkupResponsesStore } from './checkup-responses/store';
import { checkupsStore } from './checkups/store';
import { patientDetailSummaryEnrollmentsStore } from './enrollments/store';
import { miscStore } from './misc/store';
import { patientsDetailStore } from './patient-details/store';
import { providersStore } from './providers/store';
import { surveySessionsStore } from './survey-sessions/store';
import { userStore } from './user/store';
import { api } from './+plugins/smrfi-backend-api-client-plugin';
import { bannerStore } from './banner/store';

export const stores = {
  activityStore,
  capabilitiesStore,
  providerFeaturesStore,
  checkupResponsesStore,
  checkupsStore,
  patientDetailSummaryEnrollmentsStore,
  miscStore,
  patientsDetailStore,
  providersStore,
  surveySessionsStore,
  userStore,
  bannerStore,
};

export const plugins = { api };

export type Stores = {
  [Key in keyof typeof stores]: typeof stores[Key]['state'];
};

export const handle = handler<
  {
    [Key in keyof typeof plugins]: ReturnType<typeof plugins[Key]>;
  },
  Stores
>();

export const project = projector<Stores>();
