<template>
  <section class="bz-alert" :data-qa-id="id">
    <div class="bz-alert-icon"><i class="material-icons">info</i></div>
    <div class="bz-alert-message">{{ message }}</div>
  </section>
</template>

<script>
export default {
  name: 'Alert',
  props: ['id', 'message'],
};
</script>

<style lang="scss" scoped>
.bz-alert {
  display: grid;
  grid-template-columns: rem(32) 1fr;
  column-gap: rem(10);
  align-items: start;
  justify-items: start;
  padding: rem(10) rem(25);

  width: 100%;

  background: #fde8e8;
  border-radius: rem(6);
}

.bz-alert-message {
  @include md-body;
  color: #ff0f0f;
}

.bz-alert-icon {
  color: #ff0f0f;
}
</style>
