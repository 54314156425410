import { TABLE_CONFIG } from '@/constants/tableConfig';
import { ActivityState } from '@/models/activity/state.interface';
import { store } from '@conversa/reflex';

export const activityInitialState: ActivityState = {
  data: [],
  initialData: [],
  loading: false,
  error: null,
  count: null,
  tableOptions: {
    page: 1,
    itemsPerPage: TABLE_CONFIG.activity.tableRows,
  },
};

export const activityStore = store(activityInitialState);
