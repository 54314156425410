import { project } from '@/+state';
import { Activity } from '@/models/activity/activity.interface';
import { TableOptionsFrontend } from '@/shared/models/table-options';
import { dateFormatter } from '../../../../../shared/date-formatter';
import { select } from '@conversa/reflex';

const transformActivities = (activities, datetimeFormat: string) => {
  return activities.map(activity => {
    return {
      ...activity,
      formattedDate: dateFormatter(activity.date, datetimeFormat, 'dateTime'),
    };
  });
};

export const activityActivities = project<Activity[]>(({ stores }) => {
  return transformActivities(
    stores.activityStore.data,
    stores.capabilitiesStore.capabilities.international.datetimeFormat,
  );
});

export const activitySummary = project<Activity[]>(({ stores }) => {
  return transformActivities(
    stores.activityStore.initialData,
    stores.capabilitiesStore.capabilities.international.datetimeFormat,
  ).slice(0, 3);
});

export const activityActivitiesLength = project<number>(
  () => select(activityActivities).value.length,
);

export const activityCount = project<number>(({ stores }) => {
  return stores.activityStore.count;
});

export const activityLoading = project<boolean>(
  ({ stores }) => stores.activityStore.loading,
);

export const activityTableOptions = project<TableOptionsFrontend>(
  ({ stores }) => stores.activityStore.tableOptions,
);

export const activityProjections = [
  activityActivities,
  activitySummary,
  activityActivitiesLength,
  activityCount,
  activityLoading,
  activityTableOptions,
];
