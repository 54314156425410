import { SortDirCapital } from '@/shared/models';

export const TABLE_CONFIG = {
  activity: {
    tableRows: 10,
    headers: [
      {
        text: 'Activity Date',
        value: 'formattedDate',
        sortable: false,
      },
      {
        text: 'Activity',
        value: 'description',
        sortable: false,
      },
    ],
    empty: 'The patient has not had any activity',
  },
  sessions: {
    tableRows: 3,
    headers: [
      {
        content: 'Date',
        value: 'date',
      },
      {
        content: 'Description',
        value: 'description',
      },
    ],
  },
  recentChats: {
    tableRows: 3,
    sortDir: 'DESC' as SortDirCapital,
  },
  checkups: {
    tableRows: 10,
    sortDir: 'DESC' as SortDirCapital,
    headers: [
      {
        text: '',
        value: 'status',
        sortable: false,
        width: '10%',
      },
      {
        text: 'Chat Date',
        value: 'formattedDate',
        sortable: false,
      },
      {
        text: 'Program Module',
        value: 'surveyName',
        sortable: false,
      },
      { text: '', value: 'data-table-expand' },
    ],
    responses: {
      headers: [
        {
          text: '',
          value: 'status',
          sortable: false,
          width: '10%',
        },
        {
          text: 'Question',
          value: 'question',
          sortable: false,
        },
        {
          text: 'Response',
          value: 'answer',
          sortable: false,
        },
      ],
    },
  },
  surveySessions: {
    tableRows: 10,
    headers: [
      { text: 'Date', value: 'date' },
      { text: 'Program Module', value: 'surveyNames' },
    ],
  },
};
