












import { defineComponent } from '@vue/composition-api';

export default defineComponent<{
  loading: boolean;
  content?: string;
  lod?: boolean;
}>({
  props: ['loading', 'content', 'lod'],
  setup(props) {
    return {
      lightOnDark: typeof props.lod !== 'undefined',
    };
  },
});
