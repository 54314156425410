





































import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  watch,
} from '@vue/composition-api';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export default defineComponent({
  props: ['value', 'required', 'label', 'required', 'errorMessage'],
  setup(props, ctx) {
    const phoneData = reactive({
      phoneNumber: '',
      countryCode: '',
      dialCode: '',
    });

    const internalPhoneNumber = ref('');
    const phoneErrorMessage = ref(props.errorMessage);
    const isJustLoaded = ref(true);

    watch(
      () => props.errorMessage,
      newVal => {
        phoneErrorMessage.value = newVal;
      },
    );

    const clearErrors = () => {
      phoneErrorMessage.value = null;
    };

    const emitPhoneNumber = () => {
      const formattedPhoneNumber =
        phoneData.phoneNumber &&
        `+${phoneData.dialCode}${phoneData.phoneNumber.replace(/\D/g, '')}`;
      ctx.emit('input', formattedPhoneNumber);
    };

    const onCountryChange = countryData => {
      phoneData.countryCode = countryData.iso2;
      phoneData.dialCode = countryData.dialCode;
      emitPhoneNumber();
    };

    const onPhoneNumberChange = value => {
      clearErrors();
      phoneData.phoneNumber = value;
      emitPhoneNumber();
    };

    const parsePhoneNumber = phone => {
      const parsed = parsePhoneNumberFromString(phone);
      return {
        number: parsed ? parsed.nationalNumber : phone,
        countryCode: parsed ? `${parsed.countryCallingCode}` : '',
      };
    };

    onMounted(() => {
      if (props.value) {
        const parsed = parsePhoneNumber(props.value);
        phoneData.dialCode = parsed.countryCode;
        phoneData.phoneNumber = parsed.number;
        internalPhoneNumber.value = props.value;
      }
    });

    const checkRequiredError = () => {
      isJustLoaded.value = false;
    };
    return {
      isJustLoaded,
      internalPhoneNumber,
      onCountryChange,
      onPhoneNumberChange,
      phoneErrorMessage,
      checkRequiredError,
    };
  },
});
