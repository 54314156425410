import { GenderOptions } from '@conversa/bedazzled/src/models/genders.interface';

export const genderOptions: GenderOptions[] = [
  {
    value: 'f',
    content: 'Female',
  },
  {
    value: 'm',
    content: 'Male',
  },
  {
    value: 'u',
    content: 'Unknown',
  },
];
