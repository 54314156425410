import { project } from '@/+state';
import {
  CheckupResponseLoadingMap,
  CheckupResponseProjectionMap,
  CheckupResponseRenderComponentMap,
} from '@/models/checkup-responses/checkup-response.interface';

export const checkupResponsesLoading = project<CheckupResponseLoadingMap>(
  ({ stores }) => stores.checkupResponsesStore.loadingCheckupId,
);

export const checkupResponses = project<CheckupResponseProjectionMap>(
  ({ stores }) => {
    const mappedResponses = {};

    for (const checkupId in stores.checkupResponsesStore.data) {
      const responses = stores.checkupResponsesStore.data[checkupId].reduce(
        (acc, curr) => {
          acc.push(
            ...curr.answers.map(answer => {
              return {
                question: curr.question,
                answer: answer.text,
                status: answer.status,
              };
            }),
          );
          return acc;
        },
        [],
      );

      mappedResponses[checkupId] = responses;
    }

    return mappedResponses;
  },
);

export const checkupResponsesRenderComponent = project<
  CheckupResponseRenderComponentMap
>(({ stores }) => stores.checkupResponsesStore.renderComponent);

export const checkupResponsesProjections = [
  checkupResponsesLoading,
  checkupResponses,
  checkupResponsesRenderComponent,
];
