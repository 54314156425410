

































































































































































































































import {
  loadingProviderFeatures,
  providerFeaturesAlertingEnabled,
  providerFeaturesSmsEnabled,
} from '@/+state/capabilities/provider-features/projections';
import { hasEnrollments as anyEnrollments } from '@/+state/enrollments/projections';
import {
  hasPatientConsent,
  patientDetailStoreErrors,
} from '@/+state/patient-details/projections';
import EnrollmentProgramListVue from '@/dialogs/enrollments/EnrollmentProgramList.vue';
import DatePicker from '@/shared/components/DatePicker.vue';
import IntlPhone from '@/shared/components/IntlPhone.vue';
import { dateValidRule } from '@/shared/date-format-rule';
import { stateCodes } from '../../../../../shared/data/states';
import { genderOptions as defaultGenderOptions } from '@conversa/bedazzled/src/constants/genders';
import { DialogRef } from '@conversa/bedazzled/src/dialog';
import { Demographics } from '@conversa/bedazzled/src/models/demographics.interface';
import { GenderOptions } from '@conversa/bedazzled/src/models/genders.interface';
import { select } from '@conversa/reflex';
import { defineComponent, ref } from '@vue/composition-api';
import format from 'date-fns/format';
import { Patient } from '../../shared/models/patient.interface';
import { capabilitiesStore } from '../../+state/capabilities/store';

export default defineComponent<{
  canSeeLanguageSelector: boolean;
  demographicCapabilities: Demographics;
  fullscreen?: boolean;
  genderOptions?: GenderOptions;
  inputs: Patient;
  isLoadingPatient: boolean;
  maxDate?: string;
  minDate?: string;
  providerList: unknown[];
  alertMessage: unknown[];
}>({
  props: [
    'alertMessage',
    'canSeeLanguageSelector',
    'demographicCapabilities',
    'genderOptions',
    'inputs',
    'clinicPatientCode',
    'isLoadingPatient',
    'providerList',
  ],
  emits: ['cancel', 'save', 'provider-change'],
  components: { DatePicker, IntlPhone },
  setup({ genderOptions, inputs }, context) {
    const dialog = DialogRef();
    const validateForm = ref(null);
    const hasErrors = ref(false);
    const hasEnrollments = select(anyEnrollments).value;
    const hasConsent = select(hasPatientConsent).value;
    const errors = select(patientDetailStoreErrors);
    const isInNewEnrollmentFlow = !hasEnrollments && !hasConsent;

    // For date concepts (no time), we'll need to strip the time chars off
    // of the datetimeFormat string
    const dateFormat = capabilitiesStore
      .inject()
      .capabilities.international.datetimeFormat.split(' ', 1)[0];

    const showAlerts = alert => {
      if (alert) {
        const alertErrors = alert.details?.errors && {
          ...alert.details?.errors,
        };
        if (alertErrors) {
          // we only want to show errors other than inline input errors in the alert component
          for (const key in inputs) {
            delete alertErrors[key];
          }
          return Object.keys(alertErrors).length > 0 && alertErrors;
        }
        return alert.message;
      }
      return false;
    };

    return {
      providerFeaturesLoading: select(loadingProviderFeatures),
      alertingEnabled: select(providerFeaturesAlertingEnabled),
      smsEnabled: select(providerFeaturesSmsEnabled),
      showAlerts,
      validateForm,
      hasEnrollments,
      hasConsent,
      errors,
      isInNewEnrollmentFlow,
      states: stateCodes,
      format,
      hasErrors,
      rules: {
        required: v => !!v || 'This field is required',
        isSelected: v => !!v || 'Please select a communication method.',
        dateValid: date => dateValidRule(date, dateFormat, 130, 0),
      },
      genderOptionsC: genderOptions || defaultGenderOptions,
      cancel: () => {
        if (hasEnrollments) {
          context.emit('cancel');
        } else {
          dialog.open(EnrollmentProgramListVue);
        }
      },
      save: () => {
        !validateForm.value.validate()
          ? (hasErrors.value = true)
          : context.emit('save');
      },
      dateUpdated: val => (inputs.dateOfBirth = val),
      onProviderChange: providerCode =>
        context.emit('provider-change', providerCode),
      dateFormat: dateFormat,
    };
  },
});
