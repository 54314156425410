import { PatientsDetailState } from '@/models/patient-details/state.interface';
import { store } from '@conversa/reflex';

export const patientsDetailInitialState: PatientsDetailState = {
  statusLoading: false,
  profileLoading: false,
  resendChatLoading: false,
  error: null,
  data: null,
};

export const patientsDetailStore = store(patientsDetailInitialState);
