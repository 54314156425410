import { ProvidersState } from '@/models/providers/providers-state.interface';
import { store } from '@conversa/reflex';

export const providersInitialState: ProvidersState = {
  providers: null,
  loadingProviders: false,
  error: null,
};

export const providersStore = store(providersInitialState);
