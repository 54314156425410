import { event, payload } from '@conversa/reflex';

export const AppMounted = event('[ component | App ] App Mounted');

export const MiscLoading = event(
  '[ misc ] Loading',
  payload<{
    isLoading: boolean;
  }>(),
);

export const ContextInitialized = event(
  '[ api | context ] Context Initialized',
);

export const DialogUnmounted = event(
  '[ component | Dialog ] Dialog Unmounted',
  payload<{ dialogName: string }>(),
);
