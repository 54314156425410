









































import { DialogUnmounted } from '@/+state/misc/events';
import { ResendChatClicked } from '@/+state/patient-details/events';
import {
  patientDetailStoreErrors,
  resendChatLoading,
} from '@/+state/patient-details/projections';
import { DialogRef } from '@conversa/bedazzled/src/dialog';
import { emit, select } from '@conversa/reflex';
import { defineComponent, onUnmounted } from '@vue/composition-api';

export default defineComponent({
  setup() {
    const dialog = DialogRef();

    onUnmounted(() => {
      emit(DialogUnmounted({ dialogName: 'ResendChatDialog' }));
    });

    return {
      errorMessage: select(patientDetailStoreErrors),
      loading: select(resendChatLoading),
      resend: () => emit(ResendChatClicked()),
      cancel: () => {
        dialog.close();
      },
    };
  },
});
