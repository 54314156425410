
















import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  setup(_, context) {
    return {
      title: computed(() => context.attrs.title),
      message: computed(() => context.attrs.message),
    };
  },
});
