
















import { activitySummary } from '@/+state/activity/projections';
import BlueCard from '@/shared/components/BlueCard.vue';
import EmptyState from '@/shared/components/EmptyState.vue';
import { select } from '@conversa/reflex';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: { EmptyState, BlueCard },
  setup() {
    return {
      data: select(activitySummary),
      empty: 'The patient does not have any recent activity',
    };
  },
});
