











import { EnrollmentDetailsUnmounted } from '@/+state/enrollments/events';
import {
  enrollmentsError,
  enrollmentsLoading,
} from '@/+state/enrollments/projections';
import { Program } from '@/shared/models';
import { DIALOG_PROPS } from '@conversa/bedazzled/src/dialog';
import { emit, select } from '@conversa/reflex';
import { defineComponent, onUnmounted } from '@vue/composition-api';
import EnrollmentDetailsDialog from './EnrollmentDetailsDialog.vue';

export default defineComponent<{
  title: string;
  successButtonCopy: string;
  loadingCopy: string;
  createEnrollment: boolean;
  program: Program;
}>({
  props: [
    'title',
    'cancelButtonCopy',
    'successButtonCopy',
    'loadingCopy',
    'createEnrollment',
    'program',
    ...DIALOG_PROPS,
  ],
  components: {
    EnrollmentDetailsDialog,
  },
  setup(_, context) {
    onUnmounted(() => emit(EnrollmentDetailsUnmounted()));

    return {
      error: select(enrollmentsError),
      loading: select(enrollmentsLoading),
      cancel: () => context.parent.$emit('dialog-closed'),
      enroll: ({ programId, enrollmentQuestionAnswers }) =>
        context.emit('enroll', {
          programId,
          enrollmentQuestionAnswers,
        }),
    };
  },
});
