import { BannerState } from '@/models/banner/BannerState.interface';
import { store } from '@conversa/reflex';
export const bannerStateKey = 'banner-state';

export const bannerInitialState: BannerState = {
  data: null,
  timeout: null,
};

export const bannerStore = store(bannerInitialState);
